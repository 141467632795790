import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'

import ReactApexChart from 'react-apexcharts'
import ApexCharts from 'apexcharts'

import BarChartLegend from '../ApexCharts/BarChartLegend'
import EmptyStackedBarChart from '../ApexCharts/EmptyStackedBarChart'
import Download from '../Download'

import {
  CHART_COLORS,
  twoDecimalFormat,
} from '../../library/helper'

const NoData = () => {
  return (
    <div className='d-flex flex-column gap-2 pb-3 px-3'
    >
      <div style={{
        position: 'absolute',
        borderStyle: 'solid',
        borderWidth: '1px',
        padding: '0.5rem',
        borderRadius: 14,
        fontSize: '12px',
        fontWeight: 'bold',
        backgroundColor: 'white',
        alignSelf: 'center',
        top: '25%',

      }}>
        No Data
      </div>
    </div>
  )
}

function EmissionVsReduction(props) {
  const {
    chartId,
    filterLabel,
    onDownloadImage,
    startYear,
    endYear,
  } = props
  const { t, i18n } = useTranslation()

  const [keys, setKeys] = useState([])
  const [data, setData] = useState()
  const [axisBottom, setAxisBottom] = useState([])
  const [rawData, setRawData] = useState([])
  const [hiddenSeries, setHiddenSeries] = useState([])

  useEffect(() => {
    const controller = new AbortController()

    const getData = async () => {
      try {
        const response = await axios({
          withCredentials: true,
          method: 'GET',
          headers: { 'content-type': 'application/json' },
          params: {
            startYear,
            endYear,
            gwp: new URLSearchParams(window.location.search).get('gwp'),
          },
          url: `${process.env.REACT_APP_BASE_SERVER}/record-vs-reduction-summaries-lookup`,
          signal: controller.signal,
        })
        if (response.data.ok) {
          setData(response.data.data.dataChart)
          setKeys(response.data.data.categories)
          setAxisBottom(response.data.data.axisBottom)
          setRawData(response.data.data.rawData)
        } else {
          setData([])
          setKeys([])
          setAxisBottom([])
          setRawData([])
        }
      } catch (error) {
        console.error(error)
      }
    }
    if (startYear && endYear) {
      getData()
    }

    return () => controller.abort()
  }, [startYear, endYear])

  const onDownload = () => {
    onDownloadImage('emissionVsReduction', 'actual-emission-vs-reduction')
  }

  const options = {
    chart: {
      id: 'chartEmissionVsReduction',
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enable: true,
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      type: 'year',
      categories: axisBottom,
      labels: {
        show: true,
      },
      tickPlacement: 'on',
    },
    yaxis: {
      title: {
        text: 'tCO₂e',
      },
      labels: {
        formatter: function (value) {
          return twoDecimalFormat(value, i18n.language)
        },
      },
      tickAmount: 4,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val ? twoDecimalFormat(val, i18n.language) + " tCO₂e" : null
        },
      },
      enabled: true,
    },
    fill: {
      opacity: 1
    },
  }

  const series = (
    keys?.length > 0 &&
    axisBottom.length > 0 &&
    (
      rawData.emission.length > 0 ||
      rawData.reduction.length > 0
    )
  )
    ? [
      {
        name: keys.find(k => k.categoryId === 1)?.categoryName || 'series-1',
        nameId: keys.find(k => k.categoryId === 1)?.categoryNameId || 'series-1',
        data: axisBottom.map(year => {
          const findAxisBottom = rawData.emission.find(emission => emission.axisBottom === year)
          if (findAxisBottom) {
            return parseFloat(findAxisBottom.total.toFixed(2))
          } else {
            return null
          }
        }),
        color: CHART_COLORS[0],
      },
      {
        name: keys.find(k => k.categoryId === 2)?.categoryName || 'series-2',
        nameId: keys.find(k => k.categoryId === 2)?.categoryNameId || 'series-2',
        data: axisBottom.map(year => {
          const findAxisBottom = rawData.reduction.find(emission => emission.axisBottom === year)
          if (findAxisBottom) {
            return parseFloat(findAxisBottom.total.toFixed(2)) * -1
          } else {
            return null
          }
        }),
        color: CHART_COLORS[1],
      },
    ]
    : []

  if (series.length > 0 && options.tooltip) {
    // Set tooltip ID and EN label
    options.tooltip.y.title = {
      formatter: function (seriesName) {
        if (series.length && i18n.language === 'id') {
          const seriesByName = series.find(s => s.name === seriesName)
          if (seriesByName && seriesByName.nameId) {
            return `${seriesByName.nameId}:`
          } else {
            return `${seriesName}:`
          }
        } else {
          return `${seriesName}:`
        }
      },
    }
  }

  const FilterSeries = (seriesName = "") => {
    if (series.every(item => item?.name === undefined)) return

    ApexCharts.getChartByID(options?.chart?.id).toggleSeries(seriesName)

    let currentData = [...hiddenSeries]
    const checkIndex = currentData.findIndex(item => item === seriesName)

    if (checkIndex < 0) currentData.push(seriesName)
    else currentData.splice(checkIndex, 1)

    setHiddenSeries(currentData)
  }

  return (
    <div
      className='bg-white d-flex flex-column gap-3'
      style={{
        borderRadius: '8px',
        width: '100%',
      }}
      id={chartId}
    >
      <div className='align-items-start d-flex gap-3 justify-content-between pt-3 px-3 w-100'>
        <p
          className='fw-semibold m-0'
          style={{ fontSize: 12 }}
        >
          {t('scenario.label2')}
        </p>
        <Download
          onClick={onDownload}
          isDisabled={!Array.isArray(data) || !data.length}
        />
      </div>
      <div className='px-3 position-relative'>
        {
          series.length > 0
            ? <ReactApexChart
              options={options}
              series={series}
              type='bar'
              height={465}
            />
            : <EmptyStackedBarChart />
        }
        {series.length === 0 && <NoData />}
      </div>
      {
        series.length > 0 && (
          <BarChartLegend
            series={series}
            filterSeries={FilterSeries}
            hiddenSeries={hiddenSeries}
          />
        )
      }
    </div>
  )
}

export default EmissionVsReduction
