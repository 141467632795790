import React, { Component } from 'react'
import { Navigate } from 'react-router-dom'

import { connect } from "react-redux"
import { increment, decrement } from "../features/counter/counterSlice"
import { setLoggedIn } from "../features/account/loginSlice"
import { setAccount } from "../features/account/accountSlice"
import { setCompany } from "../features/account/companySlice"

import axios from "axios";
import qs from 'qs';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import ExcelJS from 'exceljs'

import {
    hidePreloader,
    showPreloader,
    twoDecimalFormat,
} from '../library/helper';
import { withTranslation } from 'react-i18next'

export class ReportISO extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectRevenue: false,
            selectedCompany: 0,
            selectedOffice: 0,
            selectedDate: new Date(Date.now()),
            selectedScope: 0,
            selectedCategory: 0,
            selectedItem: 0,
            amountItem: 0,
            amountRevenue: "",

            listEmissionRecord: [
                {
                    assets: "",
                    factor: 0,
                    amount: "",
                    unit: 0,
                    multiplier: 1,
                }
            ],

            listCategory: [],
            listFactor: [],
            itemUnit: '',

            amountEmission: 0,
            countRecords: 1,

            revenueUnit: "",

            selectedYear: new Date().getFullYear() - 1,
            selectedStandard: 1,
            listYear: [],

        }
    }

    componentDidMount = async () => {
        showPreloader();

        let listCompany = [];
        let listCategory = [];
        let listYear = [];
        let listEmissionFactor = [];
        let listEmissionFactorUnit = [];
        let reportTotal = 0;

        let reportTotalCategory1 = 0;
        let reportData11 = 0;
        let reportData21 = 0;
        let reportData31 = 0;
        let reportData41 = 0;
        let reportData51 = 0;
        let reportData61 = 0;
        let reportData71 = 0;

        let reportTotalCategory2 = 0;
        let reportData12 = 0;
        let reportData22 = 0;
        let reportData32 = 0;
        let reportData42 = 0;
        let reportData52 = 0;
        let reportData62 = 0;
        let reportData72 = 0;

        let reportTotalCategory3 = 0;
        let reportData13 = 0;
        let reportData23 = 0;
        let reportData33 = 0;
        let reportData43 = 0;
        let reportData53 = 0;
        let reportData63 = 0;
        let reportData73 = 0;

        let reportTotalCategory4 = 0;
        let reportData14 = 0;
        let reportData24 = 0;
        let reportData34 = 0;
        let reportData44 = 0;
        let reportData54 = 0;
        let reportData64 = 0;
        let reportData74 = 0;

        let reportTotalCategory5 = 0;
        let reportData15 = 0;
        let reportData25 = 0;
        let reportData35 = 0;
        let reportData45 = 0;
        let reportData55 = 0;
        let reportData65 = 0;
        let reportData75 = 0;

        let reportTotalCategory6 = 0;
        let reportData16 = 0;
        let reportData26 = 0;
        let reportData36 = 0;
        let reportData46 = 0;
        let reportData56 = 0;
        let reportData66 = 0;
        let reportData76 = 0;

        let reportTotalCategory7 = 0;
        let reportData17 = 0;
        let reportData27 = 0;
        let reportData37 = 0;
        let reportData47 = 0;
        let reportData57 = 0;
        let reportData67 = 0;
        let reportData77 = 0;

        let reportTotalCategory8 = 0;
        let reportData18 = 0;
        let reportData28 = 0;
        let reportData38 = 0;
        let reportData48 = 0;
        let reportData58 = 0;
        let reportData68 = 0;
        let reportData78 = 0;

        let reportTotalCategory9 = 0;
        let reportData19 = 0;
        let reportData29 = 0;
        let reportData39 = 0;
        let reportData49 = 0;
        let reportData59 = 0;
        let reportData69 = 0;
        let reportData79 = 0;

        let reportTotalCategory10 = 0;
        let reportData110 = 0;
        let reportData210 = 0;
        let reportData310 = 0;
        let reportData410 = 0;
        let reportData510 = 0;
        let reportData610 = 0;
        let reportData710 = 0;

        let reportTotalCategory11 = 0;
        let reportData111 = 0;
        let reportData211 = 0;
        let reportData311 = 0;
        let reportData411 = 0;
        let reportData511 = 0;
        let reportData611 = 0;
        let reportData711 = 0;

        let reportTotalCategory12 = 0;
        let reportData112 = 0;
        let reportData212 = 0;
        let reportData312 = 0;
        let reportData412 = 0;
        let reportData512 = 0;
        let reportData612 = 0;
        let reportData712 = 0;

        let reportTotalCategory13 = 0;
        let reportData113 = 0;
        let reportData213 = 0;
        let reportData313 = 0;
        let reportData413 = 0;
        let reportData513 = 0;
        let reportData613 = 0;
        let reportData713 = 0;

        let reportTotalCategory14 = 0;
        let reportData114 = 0;
        let reportData214 = 0;
        let reportData314 = 0;
        let reportData414 = 0;
        let reportData514 = 0;
        let reportData614 = 0;
        let reportData714 = 0;

        let reportTotalCategory15 = 0;
        let reportData115 = 0;
        let reportData215 = 0;
        let reportData315 = 0;
        let reportData415 = 0;
        let reportData515 = 0;
        let reportData615 = 0;
        let reportData715 = 0;

        let reportTotalCategory16 = 0;
        let reportData116 = 0;
        let reportData216 = 0;
        let reportData316 = 0;
        let reportData416 = 0;
        let reportData516 = 0;
        let reportData616 = 0;
        let reportData716 = 0;

        let reportTotalCategory17 = 0;
        let reportData117 = 0;
        let reportData217 = 0;
        let reportData317 = 0;
        let reportData417 = 0;
        let reportData517 = 0;
        let reportData617 = 0;
        let reportData717 = 0;

        let reportTotalCategory18 = 0;
        let reportData118 = 0;
        let reportData218 = 0;
        let reportData318 = 0;
        let reportData418 = 0;
        let reportData518 = 0;
        let reportData618 = 0;
        let reportData718 = 0;

        let reportTotalCategory19 = 0;
        let reportData119 = 0;
        let reportData219 = 0;
        let reportData319 = 0;
        let reportData419 = 0;
        let reportData519 = 0;
        let reportData619 = 0;
        let reportData719 = 0;

        let reportTotalCategory20 = 0;
        let reportData120 = 0;
        let reportData220 = 0;
        let reportData320 = 0;
        let reportData420 = 0;
        let reportData520 = 0;
        let reportData620 = 0;
        let reportData720 = 0;

        let reportTotalCategory21 = 0;
        let reportData121 = 0;
        let reportData221 = 0;
        let reportData321 = 0;
        let reportData421 = 0;
        let reportData521 = 0;
        let reportData621 = 0;
        let reportData721 = 0;

        let reportTotalCategory22 = 0;
        let reportData122 = 0;
        let reportData222 = 0;
        let reportData322 = 0;
        let reportData422 = 0;
        let reportData522 = 0;
        let reportData622 = 0;
        let reportData722 = 0;

        let reportTotalRemoval = 0;
        let reportRemoval1 = 0;
        let reportRemoval2 = 0;
        let reportRemoval3 = 0;
        let reportRemoval4 = 0;
        let reportRemoval5 = 0;
        let reportRemoval6 = 0;
        let reportRemoval7 = 0;

        let reportTotalBiomass = 0;
        let reportTotalBiomass2 = 0;
        let reportTotalBiomass3 = 0;
        let reportTotalBiomass4 = 0;
        let reportTotalBiomass5 = 0;
        let reportTotalBiomass6 = 0;
        let reportTotalBiomass7 = 0;

        const data = {}

        let tempRevenueUnit = "";

        let selectedYear = this.state.selectedYear;

        const optionsGetYearRecorded = {
            withCredentials: true,
            method: 'GET',
            headers: { 'content-type': 'application/json' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/record-year-and-company-summaries-lookup`
        };
        const responseGetYearRecorded = await axios(optionsGetYearRecorded);
        if (responseGetYearRecorded.data.ok) {
            listYear = responseGetYearRecorded.data.data.years
        }

        if (listYear.length == 1) {
            selectedYear = listYear[0].year;
        }

        const optionsSetting = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/account/setting/read`
        };
        const responseSetting = await axios(optionsSetting);
        if (responseSetting.data.success) {
            // eslint-disable-next-line
            if (responseSetting.data.data[0].client_revenue_unit == 1) {
                tempRevenueUnit = "IDR mn";
            } else {
                tempRevenueUnit = "USD k";
            }
        }

        const optionsGetCompany = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/client/company`
        };
        const responseGetCompany = await axios(optionsGetCompany);
        if (responseGetCompany.data.success) {
            listCompany = responseGetCompany.data.data
        }

        const optionsGetEmissionCategory = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/emission_factor/category_all`
        };
        const responseGetEmissionCategory = await axios(optionsGetEmissionCategory)
            .catch(error => {
                console.log(error);
                return { data: {} }
            });
        if (responseGetEmissionCategory.data.success) {
            listCategory = responseGetEmissionCategory.data.data
        }

        listCategory.forEach(element => {
            const data = {
                'year': selectedYear,
                'category': element.category_id
            };
            const optionsGetSummaryCategory = {
                withCredentials: true,
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data: qs.stringify({
                    ...data,
                    gwp: new URLSearchParams(window.location.search).get('gwp'),
                }),
                url: `${process.env.REACT_APP_BASE_SERVER}/emission/category`
            };
            this.getEmissionCategory(optionsGetSummaryCategory).then((responseGetSummaryCategory) => {
                if (responseGetSummaryCategory.data.success) {
                    // eslint-disable-next-line
                    if (element.category_id == 1) {
                        reportTotalCategory1 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData11 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData21 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData31 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData41 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData51 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData61 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData71 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }
                    // eslint-disable-next-line
                    if (element.category_id == 2) {
                        // direct emission stationary combustion
                        reportTotalCategory2 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData12 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData22 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData32 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData42 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData52 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData62 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData72 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }
                    // eslint-disable-next-line
                    if (element.category_id == 3) {
                        reportTotalCategory3 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData13 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData23 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData33 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData43 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData53 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData63 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData73 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }
                    // eslint-disable-next-line
                    if (element.category_id == 4) {
                        reportTotalCategory4 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData14 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData24 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData34 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData44 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData54 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData64 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData74 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }
                    // eslint-disable-next-line
                    if (element.category_id == 5) {
                        reportTotalCategory5 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData15 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData25 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData35 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData45 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData55 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData65 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData75 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }
                    // eslint-disable-next-line
                    if (element.category_id == 6) {
                        reportTotalCategory6 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData16 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData26 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData36 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData46 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData56 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData66 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData76 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }
                    // eslint-disable-next-line
                    if (element.category_id == 7) {
                        reportTotalCategory7 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData17 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData27 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData37 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData47 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData57 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData67 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData77 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }
                    // eslint-disable-next-line
                    if (element.category_id == 8) {
                        reportTotalCategory8 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData18 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData28 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData38 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData48 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData58 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData68 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData78 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }
                    // eslint-disable-next-line
                    if (element.category_id == 9) {
                        reportTotalCategory9 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData19 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData29 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData39 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData49 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData59 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData69 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData79 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }
                    // eslint-disable-next-line
                    if (element.category_id == 10) {
                        reportTotalCategory10 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData110 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData210 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData310 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData410 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData510 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData610 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData710 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }
                    // eslint-disable-next-line
                    if (element.category_id == 11) {
                        reportTotalCategory11 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData111 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData211 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData311 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData411 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData511 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData611 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData711 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }
                    // eslint-disable-next-line
                    if (element.category_id == 12) {
                        reportTotalCategory12 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData112 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData212 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData312 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData412 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData512 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData612 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData712 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }
                    // eslint-disable-next-line
                    if (element.category_id == 13) {
                        reportTotalCategory13 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData113 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData213 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData313 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData413 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData513 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData613 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData713 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }
                    // eslint-disable-next-line
                    if (element.category_id == 14) {
                        reportTotalCategory14 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData114 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData214 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData314 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData414 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData514 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData614 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData714 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }
                    // eslint-disable-next-line
                    if (element.category_id == 15) {
                        reportTotalCategory15 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData115 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData215 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData315 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData415 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData515 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData615 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData715 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }
                    // eslint-disable-next-line
                    if (element.category_id == 16) {
                        reportTotalCategory16 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData116 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData216 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData316 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData416 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData516 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData616 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData716 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }
                    // eslint-disable-next-line
                    if (element.category_id == 17) {
                        reportTotalCategory17 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData117 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData217 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData317 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData417 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData517 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData617 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData717 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }
                    // eslint-disable-next-line
                    if (element.category_id == 18) {
                        reportTotalCategory18 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData118 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData218 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData318 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData418 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData518 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData618 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData718 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }

                    // eslint-disable-next-line
                    if (element.category_id == 19) {
                        reportTotalCategory19 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData119 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData219 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData319 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData419 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData519 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData619 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData719 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }

                    // eslint-disable-next-line
                    if (element.category_id == 20) {
                        reportTotalCategory20 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData120 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData220 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData320 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData420 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData520 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData620 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData720 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }

                    // eslint-disable-next-line
                    if (element.category_id == 21) {
                        reportTotalCategory21 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData121 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData221 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData321 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData421 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData521 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData621 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData721 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }

                    // eslint-disable-next-line
                    if (element.category_id == 22) {
                        reportTotalCategory22 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData122 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData222 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData322 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData422 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData522 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData622 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData722 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }
                }
            })
        });

        const optionsGetEmissionFactor = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/emission_factor/emission_factor`
        };
        const responseGetEmissionFactor = await axios(optionsGetEmissionFactor);
        if (responseGetEmissionFactor.data.success) {
            listEmissionFactor = responseGetEmissionFactor.data.data
        }

        const optionsGetEmissionFactorUnit = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/emission_factor/emission_factor_unit`
        };
        const responseGetEmissionFactorUnit = await axios(optionsGetEmissionFactorUnit);
        if (responseGetEmissionFactorUnit.data.success) {
            listEmissionFactorUnit = responseGetEmissionFactorUnit.data.data
        }

        const dataWithYear = {
            'year': selectedYear
        };

        const optionsGetRemoval = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(dataWithYear),
            url: `${process.env.REACT_APP_BASE_SERVER}/removal/year`
        };

        const responseRemoval = await axios(optionsGetRemoval);
        if (responseRemoval.data.success) {
            let listRemoval = responseRemoval.data.data

            listRemoval.forEach(element => {
                reportTotalRemoval += element.removal_amount
            })
        }

        const optionsGetTotalBiomass = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(dataWithYear),
            url: `${process.env.REACT_APP_BASE_SERVER}/emission/total_biomass`
        };

        const responseGetTotalBiomass = await axios(optionsGetTotalBiomass);
        if (responseGetTotalBiomass.data.success) {
            reportTotalBiomass = responseGetTotalBiomass.data.data[0].record_biomass;
            reportTotalBiomass2 = responseGetTotalBiomass.data.data[0].record_biomass_2;
            reportTotalBiomass3 = responseGetTotalBiomass.data.data[0].record_biomass_3;
            reportTotalBiomass4 = responseGetTotalBiomass.data.data[0].record_biomass_4;
            reportTotalBiomass5 = responseGetTotalBiomass.data.data[0].record_biomass_5;
            reportTotalBiomass6 = responseGetTotalBiomass.data.data[0].record_biomass_6;
            reportTotalBiomass7 = responseGetTotalBiomass.data.data[0].record_biomass_7;
        }

        this.setState({
            listYear: listYear,
            listCompany: listCompany,
            listCategory: listCategory,
            listEmissionFactor: listEmissionFactor,
            listEmissionFactorUnit: listEmissionFactorUnit,
            selectedOffice: 0,
            selectedYear: selectedYear,
            revenueUnit: tempRevenueUnit,
            reportTotal: reportTotal,

            reportTotalBiomass: reportTotalBiomass,
            reportTotalBiomass2: reportTotalBiomass2,
            reportTotalBiomass3: reportTotalBiomass3,
            reportTotalBiomass4: reportTotalBiomass4,
            reportTotalBiomass5: reportTotalBiomass5,
            reportTotalBiomass6: reportTotalBiomass6,
            reportTotalBiomass7: reportTotalBiomass7,

            reportTotalCategory1: reportTotalCategory1,
            reportData11: reportData11,
            reportData21: reportData21,
            reportData31: reportData31,
            reportData41: reportData41,
            reportData51: reportData51,
            reportData61: reportData61,
            reportData71: reportData71,

            reportTotalCategory2: reportTotalCategory2,
            reportData12: reportData12,
            reportData22: reportData22,
            reportData32: reportData32,
            reportData42: reportData42,
            reportData52: reportData52,
            reportData62: reportData62,
            reportData72: reportData72,

            reportTotalCategory3: reportTotalCategory3,
            reportData13: reportData13,
            reportData23: reportData23,
            reportData33: reportData33,
            reportData43: reportData43,
            reportData53: reportData53,
            reportData63: reportData63,
            reportData73: reportData73,

            reportTotalCategory4: reportTotalCategory4,
            reportData14: reportData14,
            reportData24: reportData24,
            reportData34: reportData34,
            reportData44: reportData44,
            reportData54: reportData54,
            reportData64: reportData64,
            reportData74: reportData74,

            reportTotalCategory5: reportTotalCategory5,
            reportData15: reportData15,
            reportData25: reportData25,
            reportData35: reportData35,
            reportData45: reportData45,
            reportData55: reportData55,
            reportData65: reportData65,
            reportData75: reportData75,

            reportTotalCategory6: reportTotalCategory6,
            reportData16: reportData16,
            reportData26: reportData26,
            reportData36: reportData36,
            reportData46: reportData46,
            reportData56: reportData56,
            reportData66: reportData66,
            reportData76: reportData76,

            reportTotalCategory7: reportTotalCategory7,
            reportData17: reportData17,
            reportData27: reportData27,
            reportData37: reportData37,
            reportData47: reportData47,
            reportData57: reportData57,
            reportData67: reportData67,
            reportData77: reportData77,

            reportTotalCategory8: reportTotalCategory8,
            reportData18: reportData18,
            reportData28: reportData28,
            reportData38: reportData38,
            reportData48: reportData48,
            reportData58: reportData58,
            reportData68: reportData68,
            reportData78: reportData78,

            reportTotalCategory9: reportTotalCategory9,
            reportData19: reportData19,
            reportData29: reportData29,
            reportData39: reportData39,
            reportData49: reportData49,
            reportData59: reportData59,
            reportData69: reportData69,
            reportData79: reportData79,

            reportTotalCategory10: reportTotalCategory10,
            reportData110: reportData110,
            reportData210: reportData210,
            reportData310: reportData310,
            reportData410: reportData410,
            reportData510: reportData510,
            reportData610: reportData610,
            reportData710: reportData710,

            reportTotalCategory11: reportTotalCategory11,
            reportData111: reportData111,
            reportData211: reportData211,
            reportData311: reportData311,
            reportData411: reportData411,
            reportData511: reportData511,
            reportData611: reportData611,
            reportData711: reportData711,

            reportTotalCategory12: reportTotalCategory12,
            reportData112: reportData112,
            reportData212: reportData212,
            reportData312: reportData312,
            reportData412: reportData412,
            reportData512: reportData512,
            reportData612: reportData612,
            reportData712: reportData712,

            reportTotalCategory13: reportTotalCategory13,
            reportData113: reportData113,
            reportData213: reportData213,
            reportData313: reportData313,
            reportData413: reportData413,
            reportData513: reportData513,
            reportData613: reportData613,
            reportData713: reportData713,

            reportTotalCategory14: reportTotalCategory14,
            reportData114: reportData114,
            reportData214: reportData214,
            reportData314: reportData314,
            reportData414: reportData414,
            reportData514: reportData514,
            reportData614: reportData614,
            reportData714: reportData714,

            reportTotalCategory15: reportTotalCategory15,
            reportData115: reportData115,
            reportData215: reportData215,
            reportData315: reportData315,
            reportData415: reportData415,
            reportData515: reportData515,
            reportData615: reportData615,
            reportData715: reportData715,

            reportTotalCategory16: reportTotalCategory16,
            reportData116: reportData116,
            reportData216: reportData216,
            reportData316: reportData316,
            reportData416: reportData416,
            reportData516: reportData516,
            reportData616: reportData616,
            reportData716: reportData716,

            reportTotalCategory17: reportTotalCategory17,
            reportData117: reportData117,
            reportData217: reportData217,
            reportData317: reportData317,
            reportData417: reportData417,
            reportData517: reportData517,
            reportData617: reportData617,
            reportData717: reportData717,

            reportTotalCategory18: reportTotalCategory18,
            reportData118: reportData118,
            reportData218: reportData218,
            reportData318: reportData318,
            reportData418: reportData418,
            reportData518: reportData518,
            reportData618: reportData618,
            reportData718: reportData718,

            reportTotalCategory19: reportTotalCategory19,
            reportData119: reportData119,
            reportData219: reportData219,
            reportData319: reportData319,
            reportData419: reportData419,
            reportData519: reportData519,
            reportData619: reportData619,
            reportData719: reportData719,

            reportTotalCategory20: reportTotalCategory20,
            reportData120: reportData120,
            reportData220: reportData220,
            reportData320: reportData320,
            reportData420: reportData420,
            reportData520: reportData520,
            reportData620: reportData620,
            reportData720: reportData720,

            reportTotalCategory21: reportTotalCategory21,
            reportData121: reportData121,
            reportData221: reportData221,
            reportData321: reportData321,
            reportData421: reportData421,
            reportData521: reportData521,
            reportData621: reportData621,
            reportData721: reportData721,

            reportTotalCategory22: reportTotalCategory22,
            reportData122: reportData122,
            reportData222: reportData222,
            reportData322: reportData322,
            reportData422: reportData422,
            reportData522: reportData522,
            reportData622: reportData622,
            reportData722: reportData722,

            reportTotalRemoval: reportTotalRemoval,
            reportRemoval1: reportRemoval1,
            reportRemoval2: reportRemoval2,
            reportRemoval3: reportRemoval3,
            reportRemoval4: reportRemoval4,
            reportRemoval5: reportRemoval5,
            reportRemoval6: reportRemoval6,
            reportRemoval7: reportRemoval7,
        })

        hidePreloader();
    }

    onReload = async () => {
        showPreloader();
        let listCompany = [];
        let listCategory = [];
        let listEmissionFactor = [];
        let listEmissionFactorUnit = [];
        let reportTotal = 0;

        let reportTotalCategory1 = 0;
        let reportData11 = 0;
        let reportData21 = 0;
        let reportData31 = 0;
        let reportData41 = 0;
        let reportData51 = 0;
        let reportData61 = 0;
        let reportData71 = 0;

        let reportTotalCategory2 = 0;
        let reportData12 = 0;
        let reportData22 = 0;
        let reportData32 = 0;
        let reportData42 = 0;
        let reportData52 = 0;
        let reportData62 = 0;
        let reportData72 = 0;

        let reportTotalCategory3 = 0;
        let reportData13 = 0;
        let reportData23 = 0;
        let reportData33 = 0;
        let reportData43 = 0;
        let reportData53 = 0;
        let reportData63 = 0;
        let reportData73 = 0;

        let reportTotalCategory4 = 0;
        let reportData14 = 0;
        let reportData24 = 0;
        let reportData34 = 0;
        let reportData44 = 0;
        let reportData54 = 0;
        let reportData64 = 0;
        let reportData74 = 0;

        let reportTotalCategory5 = 0;
        let reportData15 = 0;
        let reportData25 = 0;
        let reportData35 = 0;
        let reportData45 = 0;
        let reportData55 = 0;
        let reportData65 = 0;
        let reportData75 = 0;

        let reportTotalCategory6 = 0;
        let reportData16 = 0;
        let reportData26 = 0;
        let reportData36 = 0;
        let reportData46 = 0;
        let reportData56 = 0;
        let reportData66 = 0;
        let reportData76 = 0;

        let reportTotalCategory7 = 0;
        let reportData17 = 0;
        let reportData27 = 0;
        let reportData37 = 0;
        let reportData47 = 0;
        let reportData57 = 0;
        let reportData67 = 0;
        let reportData77 = 0;

        let reportTotalCategory8 = 0;
        let reportData18 = 0;
        let reportData28 = 0;
        let reportData38 = 0;
        let reportData48 = 0;
        let reportData58 = 0;
        let reportData68 = 0;
        let reportData78 = 0;

        let reportTotalCategory9 = 0;
        let reportData19 = 0;
        let reportData29 = 0;
        let reportData39 = 0;
        let reportData49 = 0;
        let reportData59 = 0;
        let reportData69 = 0;
        let reportData79 = 0;

        let reportTotalCategory10 = 0;
        let reportData110 = 0;
        let reportData210 = 0;
        let reportData310 = 0;
        let reportData410 = 0;
        let reportData510 = 0;
        let reportData610 = 0;
        let reportData710 = 0;

        let reportTotalCategory11 = 0;
        let reportData111 = 0;
        let reportData211 = 0;
        let reportData311 = 0;
        let reportData411 = 0;
        let reportData511 = 0;
        let reportData611 = 0;
        let reportData711 = 0;

        let reportTotalCategory12 = 0;
        let reportData112 = 0;
        let reportData212 = 0;
        let reportData312 = 0;
        let reportData412 = 0;
        let reportData512 = 0;
        let reportData612 = 0;
        let reportData712 = 0;

        let reportTotalCategory13 = 0;
        let reportData113 = 0;
        let reportData213 = 0;
        let reportData313 = 0;
        let reportData413 = 0;
        let reportData513 = 0;
        let reportData613 = 0;
        let reportData713 = 0;

        let reportTotalCategory14 = 0;
        let reportData114 = 0;
        let reportData214 = 0;
        let reportData314 = 0;
        let reportData414 = 0;
        let reportData514 = 0;
        let reportData614 = 0;
        let reportData714 = 0;

        let reportTotalCategory15 = 0;
        let reportData115 = 0;
        let reportData215 = 0;
        let reportData315 = 0;
        let reportData415 = 0;
        let reportData515 = 0;
        let reportData615 = 0;
        let reportData715 = 0;

        let reportTotalCategory16 = 0;
        let reportData116 = 0;
        let reportData216 = 0;
        let reportData316 = 0;
        let reportData416 = 0;
        let reportData516 = 0;
        let reportData616 = 0;
        let reportData716 = 0;

        let reportTotalCategory17 = 0;
        let reportData117 = 0;
        let reportData217 = 0;
        let reportData317 = 0;
        let reportData417 = 0;
        let reportData517 = 0;
        let reportData617 = 0;
        let reportData717 = 0;

        let reportTotalCategory18 = 0;
        let reportData118 = 0;
        let reportData218 = 0;
        let reportData318 = 0;
        let reportData418 = 0;
        let reportData518 = 0;
        let reportData618 = 0;
        let reportData718 = 0;

        let reportTotalCategory19 = 0;
        let reportData119 = 0;
        let reportData219 = 0;
        let reportData319 = 0;
        let reportData419 = 0;
        let reportData519 = 0;
        let reportData619 = 0;
        let reportData719 = 0;

        let reportTotalCategory20 = 0;
        let reportData120 = 0;
        let reportData220 = 0;
        let reportData320 = 0;
        let reportData420 = 0;
        let reportData520 = 0;
        let reportData620 = 0;
        let reportData720 = 0;

        let reportTotalCategory21 = 0;
        let reportData121 = 0;
        let reportData221 = 0;
        let reportData321 = 0;
        let reportData421 = 0;
        let reportData521 = 0;
        let reportData621 = 0;
        let reportData721 = 0;

        let reportTotalCategory22 = 0;
        let reportData122 = 0;
        let reportData222 = 0;
        let reportData322 = 0;
        let reportData422 = 0;
        let reportData522 = 0;
        let reportData622 = 0;
        let reportData722 = 0;

        let reportTotalRemoval = 0;
        let reportRemoval1 = 0;
        let reportRemoval2 = 0;
        let reportRemoval3 = 0;
        let reportRemoval4 = 0;
        let reportRemoval5 = 0;
        let reportRemoval6 = 0;
        let reportRemoval7 = 0;

        let reportTotalBiomass = 0;

        const data = {}

        let tempRevenueUnit = "";

        const optionsSetting = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/account/setting/read`
        };
        const responseSetting = await axios(optionsSetting);
        if (responseSetting.data.success) {
            // eslint-disable-next-line
            if (responseSetting.data.data[0].client_revenue_unit == 1) {
                tempRevenueUnit = "IDR mn";
            } else {
                tempRevenueUnit = "USD k";
            }
        }

        const optionsGetCompany = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/client/company`
        };
        const responseGetCompany = await axios(optionsGetCompany);
        if (responseGetCompany.data.success) {
            listCompany = responseGetCompany.data.data
        }

        const optionsGetEmissionCategory = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/emission_factor/category`
        };
        const responseGetEmissionCategory = await axios(optionsGetEmissionCategory);
        if (responseGetEmissionCategory.data.success) {
            listCategory = responseGetEmissionCategory.data.data
        }

        listCategory.forEach(element => {
            const data = {
                'year': this.state.selectedYear,
                'category': element.category_id
            };
            const optionsGetSummaryCategory = {
                withCredentials: true,
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data: qs.stringify({
                    ...data,
                    gwp: new URLSearchParams(window.location.search).get('gwp'),
                }),
                url: `${process.env.REACT_APP_BASE_SERVER}/emission/category`
            };
            this.getEmissionCategory(optionsGetSummaryCategory).then((responseGetSummaryCategory) => {
                if (responseGetSummaryCategory.data.success) {
                    // eslint-disable-next-line
                    if (element.category_id == 1) {
                        reportTotalCategory1 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData11 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData21 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData31 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData41 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData51 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData61 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData71 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }
                    // eslint-disable-next-line
                    if (element.category_id == 2) {
                        // direct emission stationary combustion
                        reportTotalCategory2 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData12 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData22 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData32 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData42 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData52 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData62 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData72 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }
                    // eslint-disable-next-line
                    if (element.category_id == 3) {
                        reportTotalCategory3 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData13 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData23 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData33 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData43 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData53 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData63 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData73 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }
                    // eslint-disable-next-line
                    if (element.category_id == 4) {
                        reportTotalCategory4 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData14 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData24 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData34 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData44 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData54 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData64 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData74 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }
                    // eslint-disable-next-line
                    if (element.category_id == 5) {
                        reportTotalCategory5 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData15 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData25 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData35 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData45 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData55 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData65 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData75 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }
                    // eslint-disable-next-line
                    if (element.category_id == 6) {
                        reportTotalCategory6 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData16 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData26 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData36 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData46 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData56 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData66 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData76 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }
                    // eslint-disable-next-line
                    if (element.category_id == 7) {
                        reportTotalCategory7 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData17 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData27 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData37 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData47 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData57 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData67 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData77 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }
                    // eslint-disable-next-line
                    if (element.category_id == 8) {
                        reportTotalCategory8 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData18 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData28 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData38 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData48 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData58 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData68 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData78 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }
                    // eslint-disable-next-line
                    if (element.category_id == 9) {
                        reportTotalCategory9 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData19 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData29 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData39 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData49 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData59 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData69 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData79 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }
                    // eslint-disable-next-line
                    if (element.category_id == 10) {
                        reportTotalCategory10 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData110 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData210 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData310 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData410 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData510 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData610 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData710 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }
                    // eslint-disable-next-line
                    if (element.category_id == 11) {
                        reportTotalCategory11 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData111 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData211 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData311 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData411 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData511 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData611 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData711 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }
                    // eslint-disable-next-line
                    if (element.category_id == 12) {
                        reportTotalCategory12 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData112 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData212 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData312 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData412 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData512 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData612 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData712 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }
                    // eslint-disable-next-line
                    if (element.category_id == 13) {
                        reportTotalCategory13 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData113 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData213 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData313 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData413 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData513 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData613 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData713 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }
                    // eslint-disable-next-line
                    if (element.category_id == 14) {
                        reportTotalCategory14 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData114 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData214 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData314 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData414 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData514 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData614 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData714 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }
                    // eslint-disable-next-line
                    if (element.category_id == 15) {
                        reportTotalCategory15 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData115 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData215 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData315 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData415 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData515 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData615 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData715 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }
                    // eslint-disable-next-line
                    if (element.category_id == 16) {
                        reportTotalCategory16 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData116 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData216 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData316 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData416 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData516 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData616 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData716 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }
                    // eslint-disable-next-line
                    if (element.category_id == 17) {
                        reportTotalCategory17 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData117 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData217 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData317 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData417 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData517 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData617 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData717 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }
                    // eslint-disable-next-line
                    if (element.category_id == 18) {
                        reportTotalCategory18 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData118 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData218 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData318 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData418 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData518 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData618 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData718 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }

                    // eslint-disable-next-line
                    if (element.category_id == 19) {
                        reportTotalCategory19 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData119 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData219 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData319 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData419 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData519 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData619 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData719 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }

                    // eslint-disable-next-line
                    if (element.category_id == 20) {
                        reportTotalCategory20 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData120 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData220 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData320 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData420 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData520 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData620 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData720 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }

                    // eslint-disable-next-line
                    if (element.category_id == 21) {
                        reportTotalCategory21 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData121 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData221 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData321 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData421 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData521 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData621 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData721 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }

                    // eslint-disable-next-line
                    if (element.category_id == 22) {
                        reportTotalCategory22 = responseGetSummaryCategory.data.data[0].record_total != null ? responseGetSummaryCategory.data.data[0].record_total : 0;
                        reportData122 = responseGetSummaryCategory.data.data[0].record_emission != null ? responseGetSummaryCategory.data.data[0].record_emission : 0;
                        reportData222 = responseGetSummaryCategory.data.data[0].record_emission_2 != null ? responseGetSummaryCategory.data.data[0].record_emission_2 : 0;
                        reportData322 = responseGetSummaryCategory.data.data[0].record_emission_3 != null ? responseGetSummaryCategory.data.data[0].record_emission_3 : 0;
                        reportData422 = responseGetSummaryCategory.data.data[0].record_emission_4 != null ? responseGetSummaryCategory.data.data[0].record_emission_4 : 0;
                        reportData522 = responseGetSummaryCategory.data.data[0].record_emission_5 != null ? responseGetSummaryCategory.data.data[0].record_emission_5 : 0;
                        reportData622 = responseGetSummaryCategory.data.data[0].record_emission_6 != null ? responseGetSummaryCategory.data.data[0].record_emission_6 : 0;
                        reportData722 = responseGetSummaryCategory.data.data[0].record_emission_7 != null ? responseGetSummaryCategory.data.data[0].record_emission_7 : 0;
                    }
                }
            })
        });

        const optionsGetEmissionFactor = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/emission_factor/emission_factor`
        };
        const responseGetEmissionFactor = await axios(optionsGetEmissionFactor);
        if (responseGetEmissionFactor.data.success) {
            listEmissionFactor = responseGetEmissionFactor.data.data
        }

        const optionsGetEmissionFactorUnit = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/emission_factor/emission_factor_unit`
        };
        const responseGetEmissionFactorUnit = await axios(optionsGetEmissionFactorUnit);
        if (responseGetEmissionFactorUnit.data.success) {
            listEmissionFactorUnit = responseGetEmissionFactorUnit.data.data
        }

        const dataWithYear = {
            'year': this.state.selectedYear
        };

        const optionsGetRemoval = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(dataWithYear),
            url: `${process.env.REACT_APP_BASE_SERVER}/removal/year`
        };

        const responseRemoval = await axios(optionsGetRemoval);
        if (responseRemoval.data.success) {
            let listRemoval = responseRemoval.data.data

            listRemoval.forEach(element => {
                reportTotalRemoval += element.removal_amount
            })
        }

        const optionsGetTotalBiomass = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(dataWithYear),
            url: `${process.env.REACT_APP_BASE_SERVER}/emission/total_biomass`
        };

        const responseGetTotalBiomass = await axios(optionsGetTotalBiomass);
        if (responseGetTotalBiomass.data.success) {
            reportTotalBiomass = responseGetTotalBiomass.data.data[0].total_biomass;
        }

        this.setState({
            listCompany: listCompany,
            listCategory: listCategory,
            listEmissionFactor: listEmissionFactor,
            listEmissionFactorUnit: listEmissionFactorUnit,
            selectedOffice: 0,
            revenueUnit: tempRevenueUnit,
            reportTotal: reportTotal,
            reportTotalBiomass: reportTotalBiomass,

            reportTotalCategory1: reportTotalCategory1,
            reportData11: reportData11,
            reportData21: reportData21,
            reportData31: reportData31,
            reportData41: reportData41,
            reportData51: reportData51,
            reportData61: reportData61,
            reportData71: reportData71,

            reportTotalCategory2: reportTotalCategory2,
            reportData12: reportData12,
            reportData22: reportData22,
            reportData32: reportData32,
            reportData42: reportData42,
            reportData52: reportData52,
            reportData62: reportData62,
            reportData72: reportData72,

            reportTotalCategory3: reportTotalCategory3,
            reportData13: reportData13,
            reportData23: reportData23,
            reportData33: reportData33,
            reportData43: reportData43,
            reportData53: reportData53,
            reportData63: reportData63,
            reportData73: reportData73,

            reportTotalCategory4: reportTotalCategory4,
            reportData14: reportData14,
            reportData24: reportData24,
            reportData34: reportData34,
            reportData44: reportData44,
            reportData54: reportData54,
            reportData64: reportData64,
            reportData74: reportData74,

            reportTotalCategory5: reportTotalCategory5,
            reportData15: reportData15,
            reportData25: reportData25,
            reportData35: reportData35,
            reportData45: reportData45,
            reportData55: reportData55,
            reportData65: reportData65,
            reportData75: reportData75,

            reportTotalCategory6: reportTotalCategory6,
            reportData16: reportData16,
            reportData26: reportData26,
            reportData36: reportData36,
            reportData46: reportData46,
            reportData56: reportData56,
            reportData66: reportData66,
            reportData76: reportData76,

            reportTotalCategory7: reportTotalCategory7,
            reportData17: reportData17,
            reportData27: reportData27,
            reportData37: reportData37,
            reportData47: reportData47,
            reportData57: reportData57,
            reportData67: reportData67,
            reportData77: reportData77,

            reportTotalCategory8: reportTotalCategory8,
            reportData18: reportData18,
            reportData28: reportData28,
            reportData38: reportData38,
            reportData48: reportData48,
            reportData58: reportData58,
            reportData68: reportData68,
            reportData78: reportData78,

            reportTotalCategory9: reportTotalCategory9,
            reportData19: reportData19,
            reportData29: reportData29,
            reportData39: reportData39,
            reportData49: reportData49,
            reportData59: reportData59,
            reportData69: reportData69,
            reportData79: reportData79,

            reportTotalCategory10: reportTotalCategory10,
            reportData110: reportData110,
            reportData210: reportData210,
            reportData310: reportData310,
            reportData410: reportData410,
            reportData510: reportData510,
            reportData610: reportData610,
            reportData710: reportData710,

            reportTotalCategory11: reportTotalCategory11,
            reportData111: reportData111,
            reportData211: reportData211,
            reportData311: reportData311,
            reportData411: reportData411,
            reportData511: reportData511,
            reportData611: reportData611,
            reportData711: reportData711,

            reportTotalCategory12: reportTotalCategory12,
            reportData112: reportData112,
            reportData212: reportData212,
            reportData312: reportData312,
            reportData412: reportData412,
            reportData512: reportData512,
            reportData612: reportData612,
            reportData712: reportData712,

            reportTotalCategory13: reportTotalCategory13,
            reportData113: reportData113,
            reportData213: reportData213,
            reportData313: reportData313,
            reportData413: reportData413,
            reportData513: reportData513,
            reportData613: reportData613,
            reportData713: reportData713,

            reportTotalCategory14: reportTotalCategory14,
            reportData114: reportData114,
            reportData214: reportData214,
            reportData314: reportData314,
            reportData414: reportData414,
            reportData514: reportData514,
            reportData614: reportData614,
            reportData714: reportData714,

            reportTotalCategory15: reportTotalCategory15,
            reportData115: reportData115,
            reportData215: reportData215,
            reportData315: reportData315,
            reportData415: reportData415,
            reportData515: reportData515,
            reportData615: reportData615,
            reportData715: reportData715,

            reportTotalCategory16: reportTotalCategory16,
            reportData116: reportData116,
            reportData216: reportData216,
            reportData316: reportData316,
            reportData416: reportData416,
            reportData516: reportData516,
            reportData616: reportData616,
            reportData716: reportData716,

            reportTotalCategory17: reportTotalCategory17,
            reportData117: reportData117,
            reportData217: reportData217,
            reportData317: reportData317,
            reportData417: reportData417,
            reportData517: reportData517,
            reportData617: reportData617,
            reportData717: reportData717,

            reportTotalCategory18: reportTotalCategory18,
            reportData118: reportData118,
            reportData218: reportData218,
            reportData318: reportData318,
            reportData418: reportData418,
            reportData518: reportData518,
            reportData618: reportData618,
            reportData718: reportData718,

            reportTotalCategory19: reportTotalCategory19,
            reportData119: reportData119,
            reportData219: reportData219,
            reportData319: reportData319,
            reportData419: reportData419,
            reportData519: reportData519,
            reportData619: reportData619,
            reportData719: reportData719,

            reportTotalCategory20: reportTotalCategory20,
            reportData120: reportData120,
            reportData220: reportData220,
            reportData320: reportData320,
            reportData420: reportData420,
            reportData520: reportData520,
            reportData620: reportData620,
            reportData720: reportData720,

            reportTotalCategory21: reportTotalCategory21,
            reportData121: reportData121,
            reportData221: reportData221,
            reportData321: reportData321,
            reportData421: reportData421,
            reportData521: reportData521,
            reportData621: reportData621,
            reportData721: reportData721,

            reportTotalCategory22: reportTotalCategory22,
            reportData122: reportData122,
            reportData222: reportData222,
            reportData322: reportData322,
            reportData422: reportData422,
            reportData522: reportData522,
            reportData622: reportData622,
            reportData722: reportData722,

            reportTotalRemoval: reportTotalRemoval,
            reportRemoval1: reportRemoval1,
            reportRemoval2: reportRemoval2,
            reportRemoval3: reportRemoval3,
            reportRemoval4: reportRemoval4,
            reportRemoval5: reportRemoval5,
            reportRemoval6: reportRemoval6,
            reportRemoval7: reportRemoval7,
        })
        hidePreloader();
    }

    getEmissionCategory = async (data) => {
        const responseGetSummaryCategory = await axios(data);
        return responseGetSummaryCategory;
    }

    // onSelectCompany = async (e) => {
    //     let companyValue = e.target.value;
    //     const data = {
    //         'coid': companyValue
    //     };
    //     const optionsGetOffice = {
    //         withCredentials: true,
    //         method: 'POST',
    //         headers: { 'content-type': 'application/x-www-form-urlencoded' },
    //         data: qs.stringify(data),
    //         url: `${process.env.REACT_APP_BASE_SERVER}/client/office`
    //     };
    //     const responseGetOffice = await axios(optionsGetOffice);
    //     if (responseGetOffice.data.success) {
    //         this.setState({
    //             selectedCompany: companyValue,
    //             listOffice: responseGetOffice.data.data,
    //             selectedOffice: 0,
    //         })
    //     }
    // }

    // onSelectCategory = async (e) => {
    //     /*let categoryValue = e;
    //     this.setState({
    //         selectedCategory: categoryValue,
    //     })*/
    //     let categoryValue = e.target.value;
    //     this.setState({
    //         selectedCategory: categoryValue,
    //     })
    // }

    // onSelectOffice = async (e) => {
    //     let officeValue = e.target.value;
    //     this.setState({
    //         selectedOffice: officeValue,
    //     })
    // }

    onSelectYear = async (e) => {
        let yearValue = e.target.value;
        this.setState({
            selectedYear: yearValue,
        })
        this.onReload()
    }

    // onSelectStandard = async (e) => {
    //     let companyStandard = e.target.value;
    //     this.setState({
    //         selectedStandard: companyStandard
    //     })
    // }

    // onSelectDate = async (e) => {
    //     var datePick = new Date(new Date(e).getFullYear(), new Date(e).getMonth(), 7);
    //     this.setState({
    //         selectedDate: datePick,
    //     })
    // }

    // onInsertRow = async () => {
    //     let data = this.state.listEmissionRecord;
    //     data.push({
    //         assets: "",
    //         factor: 0,
    //         amount: "",
    //         unit: 0,
    //         multiplier: 1,
    //     })
    //     this.setState({
    //         countRecords: this.state.countRecords + 1,
    //     })
    // }

    // onDeleteRow = async (index) => {
    //     let data = this.state.listEmissionRecord;
    //     data.splice(index, 1)
    //     this.setState({
    //         countRecords: this.state.countRecords - 1,
    //     })
    // }

    // onInputAsset = async (e, index) => {
    //     let data = this.state.listEmissionRecord;
    //     data[index].assets = e.target.value;
    //     this.setState({
    //         listEmissionRecord: data
    //     })

    // }

    // onSelectEmission = async (e, index) => {
    //     let data = this.state.listEmissionRecord;
    //     data[index].factor = e.target.value;
    //     this.setState({
    //         listEmissionRecord: data
    //     })

    // }

    // onSelectUnit = async (e, index) => {
    //     let data = this.state.listEmissionRecord;
    //     data[index].unit = e.target.value;
    //     let listEmissionFactorUnit = this.state.listEmissionFactorUnit;
    //     let filteredUnit = listEmissionFactorUnit.filter(
    //         // eslint-disable-next-line
    //         item => item.unit_id == e.target.value
    //     )
    //     data[index].multiplier = filteredUnit[0].unit_multiplier;
    //     this.setState({
    //         listEmissionRecord: data
    //     })

    // }

    // onInputAmount = async (e) => {
    //     let amount = e.target.value;
    //     this.setState({
    //         amountRevenue: amount
    //     })

    // }

    // onSaveRecordCall = async (data) => {
    //     const options = {
    //         withCredentials: true,
    //         method: 'POST',
    //         headers: { 'content-type': 'application/x-www-form-urlencoded' },
    //         data: qs.stringify(data),
    //         url: `${process.env.REACT_APP_BASE_SERVER}/revenue/create`
    //     };
    //     const response = await axios(options);
    //     if (response.data.success) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }

    onGenerateReport = async (index) => {
        this.onDownloadImage("reportBody", this.state.selectedYear + " " + this.props.account.client_name + " Consolidated Statement of Greenhouse Gas Emissions")
    }

    onDownloadExcel = async () => {
        try {
            if (this.state.selectedYear) {
                // Create WorkBook
                const wb = new ExcelJS.Workbook()

                // Excel Properties
                wb.creator = 'TruCount'
                wb.lastModifiedBy = 'Trucount System'
                wb.created = new Date()
                wb.modified = new Date()

                // Create Sheet
                const ws = wb.addWorksheet('ISO Report', {
                    pageSetup: { fitToPage: true, fitToHeight: 5, fitToWidth: 7 },
                })
                ws.headerFooter.oddFooter = "&LGRI&C&F&RPage &P of &N";

                // Set the column width using letters or numbers
                const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
                ws.columns = numbers.map(no => ({ key: no, width: 15 }))
                ws.views = [{
                    showGridLines: false,
                }]

                // Style attributes
                const font = { size: 10 }
                const centerAlignment = {
                    vertical: 'middle',
                    horizontal: 'center',
                    wrapText: true,
                }
                const leftAlignment = {
                    vertical: 'middle',
                    horizontal: 'left',
                    wrapText: true,
                }
                const rightAlignment = {
                    vertical: 'middle', horizontal: 'right'
                }
                const style = { style: 'thin', color: { argb: 'e9ebec' } } // #e9ebec
                const otherStyle = { style: 'thin', color: { argb: 'ffffff' } } // #ffffff
                const border = {
                    top: style,
                    left: otherStyle,
                    bottom: style,
                    right: otherStyle
                }
                const fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '09564b' }, // #09564b
                }
                const fontColorWhite = { color: { argb: 'ffffff' } } // #ffffff

                const STYLE_TYPE = {
                    noBorder: 'no_border',
                    default: 'default',

                    fillDarkerBlue: 'fill_darker_blue',
                    fillBlue: 'fill_blue',
                    fillGray: 'fill_gray',

                    rightAlignment: 'right_alignment',
                    leftAlignment: 'left_alignment',
                    centerAlignment: 'center_alignment',

                    boldFont: 'bold_font',

                    bigTitleText: 'big_title_text',
                    titleText: 'title_text',
                }

                const applyCellStyles = (selectedCell, customAlignment) => {
                    selectedCell.font = font
                    selectedCell.alignment = customAlignment || leftAlignment
                    selectedCell.border = border
                }

                const renderCell = ({
                    startRow,
                    startColumn,
                    endRow,
                    endColumn,
                    value,
                    styleType,
                }) => {
                    const cell = ws.getRow(startRow).getCell(startColumn)
                    cell.value = value
                    if (
                        Array.isArray(styleType) &&
                        styleType.some(type => type === STYLE_TYPE.bigTitleText)
                    ) {
                        cell.font = { size: 24, bold: true }
                        cell.alignment = leftAlignment
                    } else if (
                        Array.isArray(styleType) &&
                        styleType.some(type => type === STYLE_TYPE.titleText)
                    ) {
                        cell.font = { size: 12, bold: true }
                        cell.alignment = leftAlignment
                    } else if (
                        Array.isArray(styleType) &&
                        styleType.some(type => type === STYLE_TYPE.noBorder)
                    ) {
                        cell.font = font
                        cell.alignment = leftAlignment
                    } else {
                        applyCellStyles(cell)
                        // Overwrite the above styles
                        if (
                            Array.isArray(styleType) &&
                            styleType.some(type => type === STYLE_TYPE.fillDarkerBlue)
                        ) {
                            cell.fill = fill
                            cell.font = { ...font, ...fontColorWhite, 'bold': true }
                            cell.border = null
                        }
                        if (
                            Array.isArray(styleType) &&
                            styleType.some(type => type === STYLE_TYPE.fillBlue)
                        ) {
                            const fgColorBlue = { fgColor: { argb: '438375' } } // #438375
                            cell.fill = { ...fill, ...fgColorBlue }
                            cell.font = { ...font, ...fontColorWhite }
                            cell.border = null
                        }
                        if (
                            Array.isArray(styleType) &&
                            styleType.some(type => type === STYLE_TYPE.fillGray)
                        ) {
                            const fgColorBlue = { fgColor: { argb: 'F5F5F5' } } // #F5F5F5
                            cell.fill = { ...fill, ...fgColorBlue }
                            cell.font = { ...font, 'bold': true }
                            cell.border = {
                                top: style,
                                left: null,
                                bottom: style,
                                right: null,
                            }
                        }
                        if (
                            Array.isArray(styleType) &&
                            styleType.some(type => type === STYLE_TYPE.rightAlignment)
                        ) {
                            cell.alignment = rightAlignment
                        }
                        if (
                            Array.isArray(styleType) &&
                            styleType.some(type => type === STYLE_TYPE.leftAlignment)
                        ) {
                            cell.alignment = leftAlignment
                        }
                        if (
                            Array.isArray(styleType) &&
                            styleType.some(type => type === STYLE_TYPE.centerAlignment)
                        ) {
                            cell.alignment = centerAlignment
                        }
                        if (
                            Array.isArray(styleType) &&
                            styleType.some(type => type === STYLE_TYPE.boldFont)
                        ) {
                            cell.font = { ...font, 'bold': true }
                        }
                    }
                    ws.mergeCells(startRow, startColumn, endRow, endColumn)
                }

                // Part 1
                const partOne = [
                    {
                        value: this.props.account.client_name,
                        startRow: 1,
                        fontSize: 24,
                        styleType: [STYLE_TYPE.bigTitleText],
                    },
                    {
                        value: Number(this.state.selectedYear),
                        startRow: 2,
                        fontSize: 12,
                        styleType: [STYLE_TYPE.titleText],
                    },
                ]
                partOne.forEach(item => {
                    renderCell({
                        startRow: item.startRow,
                        startColumn: 1,
                        endRow: item.startRow,
                        endColumn: 3,
                        value: item.value,
                        styleType: item.styleType,
                    })
                })

                const endColumnWholeRows = 10 // A to J

                // Part 2
                const startRowOfPartTwo = partOne.length + 2

                const partTwoHeaders = [
                    'EMISSIONS',
                    'CO₂e Total\n(Tonnes p.a.)',
                    'Carbon Dioxide\n(CO2)',
                    'Methane\n(CH4)',
                    'Nitrous Oxide\n(N2O)',
                    'Hydrofluoro-Carbons\n(Weighted Average)\n(HFCs)',
                    'Perfluoro-Carbons\n(Weighted Average)\n(PFCs)',
                    'Sulfur Hexafluoride\n(SF6)',
                    'Nitrogen Trifluoride\n(NF3)',
                    'Notes',
                ]
                partTwoHeaders.forEach((header, headerIndex) => {
                    renderCell({
                        startRow: startRowOfPartTwo,
                        startColumn: headerIndex + 1,
                        endRow: startRowOfPartTwo + 1,
                        endColumn: headerIndex + 1,
                        value: header,
                        styleType: [
                            STYLE_TYPE.fillDarkerBlue,
                            STYLE_TYPE.centerAlignment],
                    })
                    const headerCol = ws.getColumn(headerIndex + 1)
                    headerCol.width = headerIndex === 0 ? 69 : 18
                })

                const partTwoGWP = [
                    '', // Emission
                    'GWP',
                    twoDecimalFormat(1, this.props.i18n.language),
                    twoDecimalFormat(30, this.props.i18n.language),
                    twoDecimalFormat(265, this.props.i18n.language),
                    twoDecimalFormat(5000, this.props.i18n.language),
                    twoDecimalFormat(4000, this.props.i18n.language),
                    twoDecimalFormat(23500, this.props.i18n.language),
                    twoDecimalFormat(16100, this.props.i18n.language),
                    '', // Notes
                ]
                partTwoGWP.forEach((header, headerIndex) => {
                    renderCell({
                        startRow: startRowOfPartTwo + 2,
                        startColumn: headerIndex + 1,
                        endRow: startRowOfPartTwo + 2,
                        endColumn: headerIndex + 1,
                        value: header,
                        styleType: [
                            STYLE_TYPE.rightAlignment],
                    })
                })

                const partTwoCategories = [
                    [
                        'Category 1: Direct GHG emissions and removals in tonnes CO₂e',
                        parseFloat(this.state.reportTotalCategory1) + parseFloat(this.state.reportTotalCategory2) + parseFloat(this.state.reportTotalCategory3) + parseFloat(this.state.reportTotalCategory4) + parseFloat(this.state.reportTotalCategory5) + parseFloat(this.state.reportTotalCategory6) + parseFloat(this.state.reportTotalCategory12) + parseFloat(this.state.reportTotalCategory13) + parseFloat(this.state.reportTotalCategory14) + parseFloat(this.state.reportTotalCategory18) > 0
                            ? (parseFloat(this.state.reportTotalCategory1) + parseFloat(this.state.reportTotalCategory2) + parseFloat(this.state.reportTotalCategory3) + parseFloat(this.state.reportTotalCategory4) + parseFloat(this.state.reportTotalCategory5) + parseFloat(this.state.reportTotalCategory6) + parseFloat(this.state.reportTotalCategory12) + parseFloat(this.state.reportTotalCategory13) + parseFloat(this.state.reportTotalCategory14) + parseFloat(this.state.reportTotalCategory18)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                            : 0,
                        parseFloat(this.state.reportData11) + parseFloat(this.state.reportData12) + parseFloat(this.state.reportData13) + parseFloat(this.state.reportData14) + parseFloat(this.state.reportData15) + parseFloat(this.state.reportData16) + parseFloat(this.state.reportData112) + parseFloat(this.state.reportData113) + parseFloat(this.state.reportData114) + parseFloat(this.state.reportData118) > 0
                            ? (parseFloat(this.state.reportData11) + parseFloat(this.state.reportData12) + parseFloat(this.state.reportData13) + parseFloat(this.state.reportData14) + parseFloat(this.state.reportData15) + parseFloat(this.state.reportData16) + parseFloat(this.state.reportData112) + parseFloat(this.state.reportData113) + parseFloat(this.state.reportData114) + parseFloat(this.state.reportData118)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                            : 0,
                        parseFloat(this.state.reportData21) + parseFloat(this.state.reportData22) + parseFloat(this.state.reportData23) + parseFloat(this.state.reportData24) + parseFloat(this.state.reportData25) + parseFloat(this.state.reportData26) + parseFloat(this.state.reportData212) + parseFloat(this.state.reportData213) + parseFloat(this.state.reportData214) + parseFloat(this.state.reportData218) > 0
                            ? (parseFloat(this.state.reportData21) + parseFloat(this.state.reportData22) + parseFloat(this.state.reportData23) + parseFloat(this.state.reportData24) + parseFloat(this.state.reportData25) + parseFloat(this.state.reportData26) + parseFloat(this.state.reportData212) + parseFloat(this.state.reportData213) + parseFloat(this.state.reportData214) + parseFloat(this.state.reportData218)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                            : 0,
                        parseFloat(this.state.reportData31) + parseFloat(this.state.reportData32) + parseFloat(this.state.reportData33) + parseFloat(this.state.reportData34) + parseFloat(this.state.reportData35) + parseFloat(this.state.reportData36) + parseFloat(this.state.reportData312) + parseFloat(this.state.reportData313) + parseFloat(this.state.reportData314) + parseFloat(this.state.reportData318) > 0
                            ? (parseFloat(this.state.reportData31) + parseFloat(this.state.reportData32) + parseFloat(this.state.reportData33) + parseFloat(this.state.reportData34) + parseFloat(this.state.reportData35) + parseFloat(this.state.reportData36) + parseFloat(this.state.reportData312) + parseFloat(this.state.reportData313) + parseFloat(this.state.reportData314) + parseFloat(this.state.reportData318)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                            : 0,
                        parseFloat(this.state.reportData41) + parseFloat(this.state.reportData42) + parseFloat(this.state.reportData43) + parseFloat(this.state.reportData44) + parseFloat(this.state.reportData45) + parseFloat(this.state.reportData46) + parseFloat(this.state.reportData412) + parseFloat(this.state.reportData413) + parseFloat(this.state.reportData414) + parseFloat(this.state.reportData418) > 0
                            ? (parseFloat(this.state.reportData41) + parseFloat(this.state.reportData42) + parseFloat(this.state.reportData43) + parseFloat(this.state.reportData44) + parseFloat(this.state.reportData45) + parseFloat(this.state.reportData46) + parseFloat(this.state.reportData412) + parseFloat(this.state.reportData413) + parseFloat(this.state.reportData414) + parseFloat(this.state.reportData418)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                            : 0,
                        parseFloat(this.state.reportData51) + parseFloat(this.state.reportData52) + parseFloat(this.state.reportData53) + parseFloat(this.state.reportData54) + parseFloat(this.state.reportData55) + parseFloat(this.state.reportData56) + parseFloat(this.state.reportData512) + parseFloat(this.state.reportData513) + parseFloat(this.state.reportData514) + parseFloat(this.state.reportData518) > 0
                            ? (parseFloat(this.state.reportData51) + parseFloat(this.state.reportData52) + parseFloat(this.state.reportData53) + parseFloat(this.state.reportData54) + parseFloat(this.state.reportData55) + parseFloat(this.state.reportData56) + parseFloat(this.state.reportData512) + parseFloat(this.state.reportData513) + parseFloat(this.state.reportData514) + parseFloat(this.state.reportData518)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                            : 0,
                        parseFloat(this.state.reportData61) + parseFloat(this.state.reportData62) + parseFloat(this.state.reportData63) + parseFloat(this.state.reportData64) + parseFloat(this.state.reportData65) + parseFloat(this.state.reportData66) + parseFloat(this.state.reportData612) + parseFloat(this.state.reportData613) + parseFloat(this.state.reportData614) + parseFloat(this.state.reportData618) > 0
                            ? (parseFloat(this.state.reportData61) + parseFloat(this.state.reportData62) + parseFloat(this.state.reportData63) + parseFloat(this.state.reportData64) + parseFloat(this.state.reportData65) + parseFloat(this.state.reportData66) + parseFloat(this.state.reportData612) + parseFloat(this.state.reportData613) + parseFloat(this.state.reportData614) + parseFloat(this.state.reportData618)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                            : 0,
                        parseFloat(this.state.reportData71) + parseFloat(this.state.reportData72) + parseFloat(this.state.reportData73) + parseFloat(this.state.reportData74) + parseFloat(this.state.reportData75) + parseFloat(this.state.reportData76) + parseFloat(this.state.reportData712) + parseFloat(this.state.reportData713) + parseFloat(this.state.reportData714) + parseFloat(this.state.reportData718) > 0
                            ? (parseFloat(this.state.reportData71) + parseFloat(this.state.reportData72) + parseFloat(this.state.reportData73) + parseFloat(this.state.reportData74) + parseFloat(this.state.reportData75) + parseFloat(this.state.reportData76) + parseFloat(this.state.reportData712) + parseFloat(this.state.reportData713) + parseFloat(this.state.reportData714) + parseFloat(this.state.reportData718)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                            : 0,
                        '',
                    ],
                    [
                        'Direct emissions from stationary combustion',
                        parseFloat(this.state.reportTotalCategory2) > 0
                            ? parseFloat(this.state.reportTotalCategory2).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                            : 0,
                        parseFloat(this.state.reportData12) > 0
                            ? parseFloat(this.state.reportData12).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                            : 0,
                        parseFloat(this.state.reportData22) > 0
                            ? parseFloat(this.state.reportData22).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                            : 0,
                        parseFloat(this.state.reportData32) > 0
                            ? parseFloat(this.state.reportData32).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        parseFloat(this.state.reportData42) > 0
                            ? parseFloat(this.state.reportData42).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                            : 0,
                        parseFloat(this.state.reportData52) > 0
                            ? parseFloat(this.state.reportData52).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                            : 0,
                        parseFloat(this.state.reportData62) > 0
                            ? parseFloat(this.state.reportData62).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                            : 0,
                        parseFloat(this.state.reportData72) > 0
                            ? parseFloat(this.state.reportData72).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                            : 0,
                        '',
                    ],
                    [
                        'Direct emissions from mobile combustion',
                        parseFloat(this.state.reportTotalCategory1) > 0 ? parseFloat(this.state.reportTotalCategory1).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        parseFloat(this.state.reportData11) > 0 ? parseFloat(this.state.reportData11).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        parseFloat(this.state.reportData21) > 0 ? parseFloat(this.state.reportData21).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        parseFloat(this.state.reportData31) > 0 ? parseFloat(this.state.reportData31).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        parseFloat(this.state.reportData41) > 0 ? parseFloat(this.state.reportData41).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        parseFloat(this.state.reportData51) > 0 ? parseFloat(this.state.reportData51).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        parseFloat(this.state.reportData61) > 0 ? parseFloat(this.state.reportData61).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        parseFloat(this.state.reportData71) > 0 ? parseFloat(this.state.reportData71).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        '',
                    ],
                    [
                        'Direct process emissions and removals arise from Industrial processes',
                        parseFloat(this.state.reportTotalCategory6) > 0 ? parseFloat(this.state.reportTotalCategory6).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        parseFloat(this.state.reportData16) > 0 ? parseFloat(this.state.reportData16).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        parseFloat(this.state.reportData26) > 0 ? parseFloat(this.state.reportData26).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        parseFloat(this.state.reportData36) > 0 ? parseFloat(this.state.reportData36).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        parseFloat(this.state.reportData46) > 0 ? parseFloat(this.state.reportData46).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        parseFloat(this.state.reportData56) > 0 ? parseFloat(this.state.reportData56).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        parseFloat(this.state.reportData66) > 0 ? parseFloat(this.state.reportData66).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        parseFloat(this.state.reportData76) > 0 ? parseFloat(this.state.reportData76).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        '',
                    ],
                    [
                        'Direct fugitive emissions arise from the release of greenhouse gases in anthropogenic systems',
                        parseFloat(this.state.reportTotalCategory3) + parseFloat(this.state.reportTotalCategory4) + parseFloat(this.state.reportTotalCategory14) + parseFloat(this.state.reportTotalCategory18) > 0 ? (parseFloat(this.state.reportTotalCategory3) + parseFloat(this.state.reportTotalCategory4) + parseFloat(this.state.reportTotalCategory14) + parseFloat(this.state.reportTotalCategory18)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        parseFloat(this.state.reportData13) + parseFloat(this.state.reportData14) + parseFloat(this.state.reportData114) + parseFloat(this.state.reportData118) > 0 ? (parseFloat(this.state.reportData13) + parseFloat(this.state.reportData14) + parseFloat(this.state.reportData114) + parseFloat(this.state.reportData118)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        parseFloat(this.state.reportData23) + parseFloat(this.state.reportData24) + parseFloat(this.state.reportData214) + parseFloat(this.state.reportData218) > 0 ? (parseFloat(this.state.reportData23) + parseFloat(this.state.reportData24) + parseFloat(this.state.reportData214) + parseFloat(this.state.reportData218)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        parseFloat(this.state.reportData33) + parseFloat(this.state.reportData34) + parseFloat(this.state.reportData314) + parseFloat(this.state.reportData318) > 0 ? (parseFloat(this.state.reportData33) + parseFloat(this.state.reportData34) + parseFloat(this.state.reportData314) + parseFloat(this.state.reportData318)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        parseFloat(this.state.reportData43) + parseFloat(this.state.reportData44) + parseFloat(this.state.reportData414) + parseFloat(this.state.reportData418) > 0 ? (parseFloat(this.state.reportData43) + parseFloat(this.state.reportData44) + parseFloat(this.state.reportData414) + parseFloat(this.state.reportData418)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        parseFloat(this.state.reportData53) + parseFloat(this.state.reportData54) + parseFloat(this.state.reportData514) + parseFloat(this.state.reportData518) > 0 ? (parseFloat(this.state.reportData53) + parseFloat(this.state.reportData54) + parseFloat(this.state.reportData514) + parseFloat(this.state.reportData518)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        parseFloat(this.state.reportData63) + parseFloat(this.state.reportData64) + parseFloat(this.state.reportData614) + parseFloat(this.state.reportData618) > 0 ? (parseFloat(this.state.reportData63) + parseFloat(this.state.reportData64) + parseFloat(this.state.reportData614) + parseFloat(this.state.reportData618)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        parseFloat(this.state.reportData73) + parseFloat(this.state.reportData74) + parseFloat(this.state.reportData714) + parseFloat(this.state.reportData718) > 0 ? (parseFloat(this.state.reportData73) + parseFloat(this.state.reportData74) + parseFloat(this.state.reportData714) + parseFloat(this.state.reportData718)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        ''
                    ],
                    [
                        'Direct emissions and removals from Land Use, Land Use Change and Forestry',
                        parseFloat(this.state.reportTotalCategory13) + parseFloat(this.state.reportTotalCategory19) > 0 ? parseFloat(this.state.reportTotalCategory13 + this.state.reportTotalCategory19).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        parseFloat(this.state.reportData113) + this.state.reportData119 > 0 ? parseFloat(this.state.reportData113 + this.state.reportData119).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        parseFloat(this.state.reportData213) + this.state.reportData219 > 0 ? parseFloat(this.state.reportData213 + this.state.reportData219).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        parseFloat(this.state.reportData313) + this.state.reportData319 > 0 ? parseFloat(this.state.reportData313 + this.state.reportData319).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        parseFloat(this.state.reportData413) + this.state.reportData419 > 0 ? parseFloat(this.state.reportData413 + this.state.reportData419).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        parseFloat(this.state.reportData513) + this.state.reportData519 > 0 ? parseFloat(this.state.reportData513 + this.state.reportData519).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        parseFloat(this.state.reportData613) + this.state.reportData619 > 0 ? parseFloat(this.state.reportData613 + this.state.reportData619).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        parseFloat(this.state.reportData713) + this.state.reportData719 > 0 ? parseFloat(this.state.reportData713 + this.state.reportData719).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        '',
                    ],
                    [
                        'Direct emissions in tonnes of CO₂e from biomass',
                        parseFloat(this.state.reportTotalBiomass) +
                            parseFloat(this.state.reportTotalBiomass2) +
                            parseFloat(this.state.reportTotalBiomass3) +
                            parseFloat(this.state.reportTotalBiomass4) +
                            parseFloat(this.state.reportTotalBiomass5) +
                            parseFloat(this.state.reportTotalBiomass6) +
                            parseFloat(this.state.reportTotalBiomass7) > 0 ?
                            (
                                parseFloat(this.state.reportTotalBiomass) +
                                parseFloat(this.state.reportTotalBiomass2) +
                                parseFloat(this.state.reportTotalBiomass3) +
                                parseFloat(this.state.reportTotalBiomass4) +
                                parseFloat(this.state.reportTotalBiomass5) +
                                parseFloat(this.state.reportTotalBiomass6) +
                                parseFloat(this.state.reportTotalBiomass7)
                            ).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        parseFloat(this.state.reportTotalBiomass) > 0 ? parseFloat(this.state.reportTotalBiomass).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        parseFloat(this.state.reportTotalBiomass2) > 0 ? parseFloat(this.state.reportTotalBiomass2).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        parseFloat(this.state.reportTotalBiomass3) > 0 ? parseFloat(this.state.reportTotalBiomass3).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,

                        parseFloat(this.state.reportTotalBiomass4) > 0 ? parseFloat(this.state.reportTotalBiomass4).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        parseFloat(this.state.reportTotalBiomass5) > 0 ? parseFloat(this.state.reportTotalBiomass5).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        parseFloat(this.state.reportTotalBiomass6) > 0 ? parseFloat(this.state.reportTotalBiomass6).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        parseFloat(this.state.reportTotalBiomass7) > 0 ? parseFloat(this.state.reportTotalBiomass7).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        '',
                    ],
                ]

                partTwoCategories.forEach((categoryOneItems, categoryOneItemsIndex) => {
                    categoryOneItems.forEach((itemColumn, itemColumnIndex) => {
                        const isSubHeader = categoryOneItemsIndex === 0 ||
                            categoryOneItemsIndex === partTwoCategories.length - 1
                        const styleType = []
                        if (isSubHeader) styleType.push(STYLE_TYPE.fillGray)
                        if (itemColumnIndex === 0) {
                            styleType.push(STYLE_TYPE.leftAlignment)
                        } else {
                            styleType.push(STYLE_TYPE.rightAlignment)
                        }
                        renderCell({
                            startRow: startRowOfPartTwo + 3 + categoryOneItemsIndex,
                            startColumn: itemColumnIndex + 1,
                            endRow: startRowOfPartTwo + 3 + categoryOneItemsIndex,
                            endColumn: itemColumnIndex + 1,
                            value: itemColumn,
                            styleType,
                        })
                    })
                })

                // Part 3
                const startRowOfPartThree = startRowOfPartTwo + 3 + partTwoCategories.length + 1

                renderCell({
                    startRow: startRowOfPartThree - 1,
                    startColumn: 1,
                    endRow: startRowOfPartThree - 1,
                    endColumn: endColumnWholeRows,
                    value: '',
                    styleType: [STYLE_TYPE.centerAlignment],
                })

                const partThreeRows = [
                    [
                        'Indirect Emissions in tonnes CO₂e',
                        this.state.reportTotalCategory7 > 0 ? parseFloat(this.state.reportTotalCategory7).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                    ],
                    [
                        'Category 2: Indirect GHG emissions from imported energy',
                        0,
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                    ],
                    [
                        'Indirect emissions from imported electricity',
                        this.state.reportTotalCategory7 > 0 ? parseFloat(this.state.reportTotalCategory7).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                    ],
                    [
                        'Indirect emissions from imported energy',
                        0,
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                    ],
                    [
                        'Category 3: Indirect GHG emissions from transportation',
                        0,
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                    ],
                    [
                        'Emissions from Upstream transport and distribution for goods',
                        this.state.reportTotalCategory8 > 0 ? parseFloat(this.state.reportTotalCategory8).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',

                    ],
                    [
                        'Emissions from Downstreams transport and distribution for goods',
                        this.state.reportTotalCategory9 > 0 ? parseFloat(this.state.reportTotalCategory9).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                    ],
                    [
                        'Emissions from Employee commuting includes emissions',
                        0,
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                    ],
                    [
                        'Emissions from Client and visitor transport',
                        0,
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                    ],
                    [
                        'Emissions from Business travels',
                        0,
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                    ],
                    [
                        'Category 4: Indirect GHG emissions from products used by organization',
                        0,
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                    ],
                    [
                        'Emissions from Purchased goods',
                        this.state.reportTotalCategory10 > 0 ? parseFloat(this.state.reportTotalCategory10).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                    ],
                    [
                        'Emissions from Capital goods',
                        this.state.reportTotalCategory11 > 0 ? parseFloat(this.state.reportTotalCategory11).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',

                    ],
                    [
                        'Emissions from the disposal of solid and liquid waste',
                        0,
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                    ],
                    [
                        'Emissions from the use of assets',
                        0,
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                    ],
                    [
                        'Emissions from the use of services that are not described in the above subcategories (consulting, cleaning, maintenance, mail delivery, bank, etc.)',
                        0,
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                    ],
                    [
                        'Category 5: Indirect GHG emissions associated with the use of products from the organization',
                        this.state.reportTotalCategory15 > 0 ? parseFloat(this.state.reportTotalCategory15).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                    ],
                    [
                        'Emissions or removals from the use stage of the product',
                        0,
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                    ],
                    [
                        'Emissions from downstream leased assets',
                        0,
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                    ],
                    [
                        'Emissions from end of life stage of the product',
                        0,
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                    ],
                    [
                        'Emissions from Investments',
                        this.state.reportTotalCategory17 > 0 ? parseFloat(this.state.reportTotalCategory17).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                    ],
                    [
                        'Category 6: Indirect GHG emissions from other sources',
                        0,
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                    ],
                    [
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                    ],
                    [
                        'TOTAL EMISSIONS CATEGORIES 1-6',
                        parseFloat(this.state.reportTotalCategory1) +
                            parseFloat(this.state.reportTotalCategory2) +
                            parseFloat(this.state.reportTotalCategory3) +
                            parseFloat(this.state.reportTotalCategory4) +
                            parseFloat(this.state.reportTotalCategory5) +
                            parseFloat(this.state.reportTotalCategory6) +
                            parseFloat(this.state.reportTotalCategory7) +
                            parseFloat(this.state.reportTotalCategory8) +
                            parseFloat(this.state.reportTotalCategory9) +
                            parseFloat(this.state.reportTotalCategory10) +
                            parseFloat(this.state.reportTotalCategory11) +
                            parseFloat(this.state.reportTotalCategory12) +
                            parseFloat(this.state.reportTotalCategory13) +
                            parseFloat(this.state.reportTotalCategory14) +
                            parseFloat(this.state.reportTotalCategory15) +
                            parseFloat(this.state.reportTotalCategory16) +
                            parseFloat(this.state.reportTotalCategory17) +
                            parseFloat(this.state.reportTotalCategory18) +
                            parseFloat(this.state.reportTotalCategory19) > 0
                            ? (
                                parseFloat(this.state.reportTotalCategory1) +
                                parseFloat(this.state.reportTotalCategory2) +
                                parseFloat(this.state.reportTotalCategory3) +
                                parseFloat(this.state.reportTotalCategory4) +
                                parseFloat(this.state.reportTotalCategory5) +
                                parseFloat(this.state.reportTotalCategory6) +
                                parseFloat(this.state.reportTotalCategory7) +
                                parseFloat(this.state.reportTotalCategory8) +
                                parseFloat(this.state.reportTotalCategory9) +
                                parseFloat(this.state.reportTotalCategory10) +
                                parseFloat(this.state.reportTotalCategory11) +
                                parseFloat(this.state.reportTotalCategory12) +
                                parseFloat(this.state.reportTotalCategory13) +
                                parseFloat(this.state.reportTotalCategory14) +
                                parseFloat(this.state.reportTotalCategory15) +
                                parseFloat(this.state.reportTotalCategory16) +
                                parseFloat(this.state.reportTotalCategory17) +
                                parseFloat(this.state.reportTotalCategory18) +
                                parseFloat(this.state.reportTotalCategory19)
                            ).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                            : 0,
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                    ],
                    [
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                    ],
                    [
                        'REMOVALS',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                    ],
                    [
                        'Direct removals in tonnes CO₂e',
                        parseFloat(this.state.reportTotalRemoval) > 0 ? parseFloat(this.state.reportTotalRemoval).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        parseFloat(this.state.reportTotalRemoval) > 0 ? parseFloat(this.state.reportTotalRemoval).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        parseFloat(this.state.reportRemoval2) > 0 ? parseFloat(this.state.reportRemoval2).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        parseFloat(this.state.reportRemoval3) > 0 ? parseFloat(this.state.reportRemoval3).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,

                        parseFloat(this.state.reportRemoval4) > 0 ? parseFloat(this.state.reportRemoval4).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        parseFloat(this.state.reportRemoval5) > 0 ? parseFloat(this.state.reportRemoval5).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        parseFloat(this.state.reportRemoval6) > 0 ? parseFloat(this.state.reportRemoval6).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        parseFloat(this.state.reportRemoval7) > 0 ? parseFloat(this.state.reportRemoval7).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0,
                        '',
                    ],
                    [
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                    ],
                    [
                        'STORAGE',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                    ],
                    [
                        'Total storage as of year end in tonnes CO₂e',
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        '',
                    ],
                    [
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                    ],
                    [
                        'CARBON FINANCIAL INSTRUMENTS',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                    ],
                    [
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                    ]
                ]

                const partThreeSubHeaderIndexes = [
                    1, 4, 10, 16, 21, 23, 25, 28, 31
                ]

                partThreeRows.forEach((items, itemsIndex) => {
                    items.forEach((itemColumn, itemColumnIndex) => {
                        const isSubHeader = partThreeSubHeaderIndexes.includes(itemsIndex)

                        const styleType = []
                        if (isSubHeader) styleType.push(STYLE_TYPE.fillGray)
                        if (itemsIndex > 25) {
                            styleType.push(STYLE_TYPE.boldFont)
                        }
                        if (itemColumnIndex === 0) {
                            styleType.push(STYLE_TYPE.leftAlignment)
                        } else {
                            styleType.push(STYLE_TYPE.rightAlignment)
                        }

                        renderCell({
                            startRow: startRowOfPartThree + itemsIndex,
                            startColumn: itemColumnIndex + 1,
                            endRow: startRowOfPartThree + itemsIndex,
                            endColumn: itemColumnIndex + 1,
                            value: itemColumn,
                            styleType,
                        })
                    })
                })

                const startRowOfPartFour = startRowOfPartThree + partThreeRows.length

                const partFourRows = [
                    'Other related information',
                    'Performance tracking (emissions and removals by metric, e.g. tonnes CO₂e per annual revenue)',
                    'Base year GHG emissions, removals, and stocks; and adjustments to base year',
                    'Disclosure of most significant sources, sinks, and reservoirs',
                    'Statements of emissions (CO₂e) per unit of relevant units',
                    'Statement of emission reduction initiatives',
                    'Significancy criteria',
                    'Uncertainty assessment',
                    'Notes',
                    '[*] Significant / Non Significant',
                ]

                partFourRows.forEach((item, itemIndex) => {
                    renderCell({
                        startRow: startRowOfPartFour + itemIndex,
                        startColumn: 1,
                        endRow: startRowOfPartFour + itemIndex,
                        endColumn: endColumnWholeRows,
                        value: item,
                        styleType: itemIndex === 0 ||
                            itemIndex === 8
                            ? [STYLE_TYPE.fillGray, STYLE_TYPE.leftAlignment]
                            : [STYLE_TYPE.noBorder],
                    })
                })

                for (let row = 1; row <= ws.rowCount; row++) {
                    const currentRow = ws.getRow(row)
                    currentRow.height = 30
                }

                // Create xlsx file
                const fileLabel = 'Consolidated Statement of Greenhouse Gas Emissions'
                const fileName = `${this.state.selectedYear} ${this.props.account.client_name} ${fileLabel}`
                wb.xlsx.writeBuffer().then(function (data) {
                    const blob = new Blob([data], {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    })
                    const url = window.URL.createObjectURL(blob)
                    const anchor = document.createElement("a")
                    anchor.href = url
                    anchor.download = `${fileName}.xlsx`
                    anchor.click()
                    window.URL.revokeObjectURL(url)
                })
            }
        } catch (error) {
            console.log(error, 'e')
        }
    }

    onDownloadImage = async (element, label) => {
        const canvas = await html2canvas(document.getElementById(element));
        const image = canvas.toDataURL("image/png", 1.0);

        var imgWidth = 210;
        var pageHeight = 300;
        var imgHeight = (canvas.height - 30) * imgWidth / canvas.width;
        var heightLeft = imgHeight;
        var doc = new jsPDF('p', 'mm');
        var position = 8;

        doc.addImage(image, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
            position += heightLeft - imgHeight + 5; // top padding for other pages
            doc.addPage();
            doc.addImage(image, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
        }
        doc.save(label + '.pdf');
    }

    render() {
        const { redirectRevenue } = this.state;
        const { listCompany, selectedCompany } = this.state;
        const { listOffice, selectedOffice } = this.state;
        const { listCategory, selectedCategory } = this.state;
        const { listEmissionFactor, listEmissionFactorUnit, listEmissionRecord } = this.state;
        const { countRecords } = this.state;

        if (redirectRevenue) {
            return <Navigate to={'/revenue'} />
        }

        // let optionCompany = [];
        // optionCompany.push(selectedCompany > 0 ? null : <option key={0} value={0}>Select Company</option>)
        // // eslint-disable-next-line
        // if (listCompany != undefined) {
        //     if (listCompany.length> 0) {
        //         listCompany.map(async (item, index) => {
        //             optionCompany.push(<option key={index + 1} value={item.company_id}>{item.company_name}</option>)
        //         })
        //     }
        // }

        // let optionOffice = [];
        // optionOffice.push(selectedOffice > 0 ? null : <option key={0} value={0}>Select Location</option>)
        // // eslint-disable-next-line
        // if (listOffice != undefined) {
        //     if (listOffice.length> 0) {
        //         listOffice.map(async (item, index) => {
        //             optionOffice.push(<option key={index + 1} value={item.office_id}>{item.office_name}</option>)
        //         })
        //     }
        // }

        // let optionCategory = [];
        // optionCategory.push(selectedCategory > 0 ? null : <option key={0} value={0}>Select Source</option>)
        // // eslint-disable-next-line
        // if (listCategory != undefined) {
        //     if (listCategory.length> 0) {
        //         listCategory.map(async (item, index) => {
        //             optionCategory.push(<option key={index + 1} value={item.category_id}>{item.category_name}</option>)
        //             // eslint-disable-next-line
        //             /*if(item.category_id == selectedCategory){
        //                 optionCategory.push(
        //                     <button key={index} onClick={() => this.onSelectCategory(item.category_id)} className='text-center rounded-md border border-transparent bg-indigo-600 py-2 h-full w-full align-middle text-sm font-medium text-white hover:bg-indigo-700 col-span-6 md:col-span-6'>
        //                     {item.category_name}</button>
        //                 )
        //             }else{
        //                 optionCategory.push(
        //                     <button key={index} onClick={() => this.onSelectCategory(item.category_id)} className='text-center rounded-md border border-transparent bg-indigo-300 py-2 h-full w-full align-middle text-sm font-medium text-white hover:bg-indigo-700 col-span-6 md:col-span-6'>
        //                     {item.category_name}</button>
        //                 )
        //             }*/
        //         })
        //     }
        // }

        // let optionEmissionFactor = [];
        // // eslint-disable-next-line
        // if (selectedCategory != 0) {
        //     // eslint-disable-next-line
        //     if (listEmissionFactor != undefined) {
        //         if (listEmissionFactor.length> 0) {
        //             listEmissionFactor.map(async (item, index) => {
        //                 // eslint-disable-next-line
        //                 if (item.fc_category == selectedCategory) {
        //                     optionEmissionFactor.push(
        //                         <option key={index + 1} value={item.emission_factor_id}>{item.emission_name}</option>
        //                     )
        //                 }
        //             })
        //         }
        //     }
        // }

        // let emissionRows = [];
        // for (let i = 0; i < countRecords; i++) {
        //     let optionEmissionFactorUnit = [];
        //     // eslint-disable-next-line
        //     if (listEmissionRecord[i].factor != 0) {
        //         // eslint-disable-next-line
        //         if (listEmissionFactorUnit != undefined) {
        //             if (listEmissionFactorUnit.length> 0) {
        //                 listEmissionFactorUnit.map(async (item, index) => {
        //                     // eslint-disable-next-line
        //                     if (item.unit_factor == listEmissionRecord[i].factor) {
        //                         optionEmissionFactorUnit.push(
        //                             <option key={index + 1} value={item.unit_id}>{item.unit_label}</option>
        //                         )
        //                     }
        //                 })
        //             }
        //         }
        //     }

        //     if (optionEmissionFactor.length> 0) {
        //         emissionRows.push(
        //             <tr key={i}>
        //                 <td>
        //                     <input
        //                         onChange={e => this.onInputAsset(e, i)}
        //                         value={listEmissionRecord[i].assets}
        //                         className="form-control"
        //                         placeholder="assets"
        //                     ></input>
        //                 </td>
        //                 <td>
        //                     <select onChange={e => this.onSelectEmission(e, i)} value={listEmissionRecord[i].factor} className="form-select mb-0">
        //                         {/* eslint-disable-next-line */}
        //                         {listEmissionRecord[i].factor > 0 ? "" : <option value="0">Select {selectedCategory == 7 ? "Grid Name" : "Fuel Type"}</option>}
        //                         {optionEmissionFactor}
        //                     </select>
        //                 </td>
        //                 <td>
        //                     <select onChange={e => this.onSelectUnit(e, i)} value={listEmissionRecord[i].unit} className="form-select mb-0">
        //                         {listEmissionRecord[i].unit > 0 ? "" : <option value="0">Select Unit</option>}
        //                         {optionEmissionFactorUnit}
        //                     </select>
        //                 </td>
        //                 <td>
        //                     <input
        //                         type="number"
        //                         onChange={e => this.onInputAmount(e, i)}
        //                         value={listEmissionRecord[i].amount}
        //                         className="form-control"
        //                         placeholder="amount"
        //                     ></input>
        //                 </td>
        //                 <td>
        //                     <button onClick={() => this.onDeleteRow(i)} type="button" className="btn btn-icon btn-ghost-danger"><i className="ri-delete-bin-5-line"></i></button>
        //                 </td>
        //             </tr>
        //         )
        //     } else {
        //         emissionRows.push(
        //             <tr key={i}>
        //                 <td>
        //                     <input
        //                         onChange={e => this.onInputAsset(e, i)}
        //                         value={listEmissionRecord[i].assets}
        //                         className="form-control"
        //                         placeholder="assets"
        //                     ></input>
        //                 </td>
        //                 <td>
        //                     <input
        //                         type="number"
        //                         onChange={e => this.onInputAmount(e, i)}
        //                         value={listEmissionRecord[i].amount}
        //                         className="form-control"
        //                         placeholder="amount"
        //                     ></input>
        //                 </td>
        //                 <td>
        //                     <button onClick={() => this.onDeleteRow(i)} type="button" className="btn btn-icon btn-ghost-danger"><i className="ri-delete-bin-5-line"></i></button>
        //                 </td>
        //             </tr>
        //         )
        //     }
        // }

        let optionYear = [];
        this.state.listYear.map(async (item, index) => {
            //if(item.year != new Date().getFullYear()){
            optionYear.push(
                <option key={index} value={item.year}>{item.year}</option>
            )
            //}
        })

        return (
            <div className="page-content">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0">{this.props.t('report.label1')}</h4>

                                {/*<div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="#page">Pages</a></li>
                                        <li className="breadcrumb-item active">Starter</li>
                                    </ol>
                                </div>*/}

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body" id="reportBody">
                                    <div className="row px-3">
                                        <div className="align-items-start d-flex justify-content-between mb-3 px-0 gap-3 flex-wrap">
                                            <div className="position-relative">
                                                <h4 className='fw-bold m-0' style={{ fontSize: '24px' }}>{this.props.account.client_name}</h4>
                                                <div className='d-flex gap-2'>
                                                    <p className='m-0'>{this.state.selectedYear}</p>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-wrap gap-2 align-items-baseline">
                                                <select
                                                    style={{
                                                        width: "fit-content",
                                                        // TODO: Set up form-select padding at the app level instead
                                                        padding: '0.3rem 2.1rem 0.3rem 0.9rem',
                                                    }}
                                                    onChange={e => this.onSelectYear(e)}
                                                    value={this.state.selectedYear}
                                                    id="company"
                                                    name="company"
                                                    className="form-select form-select-sm"
                                                >
                                                    {optionYear}
                                                </select>
                                                <div className="flex-shrink-0" data-html2canvas-ignore="true">
                                                    <ul className="list-inline text-end mb-0">
                                                        <li className="list-inline-item m-0">
                                                            <div className="dropdown">
                                                                <button
                                                                    className="btn btn-ghost-secondary btn-icon btn-sm"
                                                                    type="button"
                                                                    data-bs-toggle="dropdown"
                                                                    aria-haspopup="true"
                                                                    aria-expanded="false"
                                                                >
                                                                    <i className="ri-more-2-fill "></i>
                                                                </button>
                                                                <div className="dropdown-menu dropdown-menu-end">
                                                                    <button
                                                                        type="button"
                                                                        className="dropdown-item"
                                                                        onClick={this.onGenerateReport}
                                                                    >
                                                                        <i className="ri-file-line align-bottom text-muted me-2"></i>
                                                                        {this.props.t('report.saveAsPdf')}
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className="dropdown-item"
                                                                        onClick={this.onDownloadExcel}
                                                                    >
                                                                        <i className="ri-file-excel-line align-bottom text-muted me-2"></i>
                                                                        {this.props.t('report.saveAsExcel')}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 overflow-auto p-0">
                                            <table className='sticky table table-iso-container'>
                                                <thead>
                                                    <tr>
                                                        <th style={{ minWidth: '200px' }}>EMISSIONS</th>
                                                        <th>CO<sub>2</sub>e Total<br />(Tonnes p.a.)</th>
                                                        <th>Carbon Dioxide <br /> (CO2)</th>
                                                        <th>Methane <br /> (CH4)</th>
                                                        <th>Nitrous Oxide <br /> (N2O)</th>

                                                        <th>Hydrofluoro-Carbons <br /> (Weighted Average) <br /> (HFCs)</th>
                                                        <th>Perfluoro-Carbons <br /> (Weighted Average) <br /> (PFCs)</th>
                                                        <th>Sulfur Hexaflouride <br /> (SF6)</th>
                                                        <th>Nitrogen Triflouride <br /> (NF3)</th>
                                                        <th>Notes</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td style={{ minWidth: '200px' }}>&nbsp;</td>

                                                        <td>GWP</td>
                                                        <td>1</td>
                                                        <td>30</td>
                                                        <td>265</td>

                                                        <td>5,000</td>
                                                        <td>4,000</td>
                                                        <td>23,500</td>
                                                        <td>16,100</td>
                                                        <td>&nbsp;</td>
                                                    </tr>

                                                    <tr>
                                                        <th style={{ textAlign: 'start' }}>Category 1: Direct GHG emissions and removals in tonnes CO<sub>2</sub>e</th>

                                                        <th>{
                                                            (parseFloat(this.state.reportTotalCategory1) + parseFloat(this.state.reportTotalCategory2) + parseFloat(this.state.reportTotalCategory3) + parseFloat(this.state.reportTotalCategory4) + parseFloat(this.state.reportTotalCategory5) + parseFloat(this.state.reportTotalCategory6) + parseFloat(this.state.reportTotalCategory12) + parseFloat(this.state.reportTotalCategory13) + parseFloat(this.state.reportTotalCategory14) + parseFloat(this.state.reportTotalCategory18)) > 0 ?
                                                                (parseFloat(this.state.reportTotalCategory1) + parseFloat(this.state.reportTotalCategory2) + parseFloat(this.state.reportTotalCategory3) + parseFloat(this.state.reportTotalCategory4) + parseFloat(this.state.reportTotalCategory5) + parseFloat(this.state.reportTotalCategory6) + parseFloat(this.state.reportTotalCategory12) + parseFloat(this.state.reportTotalCategory13) + parseFloat(this.state.reportTotalCategory14) + parseFloat(this.state.reportTotalCategory18)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</th>
                                                        <th>{(parseFloat(this.state.reportData11) + parseFloat(this.state.reportData12) + parseFloat(this.state.reportData13) + parseFloat(this.state.reportData14) + parseFloat(this.state.reportData15) + parseFloat(this.state.reportData16) + parseFloat(this.state.reportData112) + parseFloat(this.state.reportData113) + parseFloat(this.state.reportData114) + parseFloat(this.state.reportData118)) > 0 ? (parseFloat(this.state.reportData11) + parseFloat(this.state.reportData12) + parseFloat(this.state.reportData13) + parseFloat(this.state.reportData14) + parseFloat(this.state.reportData15) + parseFloat(this.state.reportData16) + parseFloat(this.state.reportData112) + parseFloat(this.state.reportData113) + parseFloat(this.state.reportData114) + parseFloat(this.state.reportData118)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</th>
                                                        <th>{(parseFloat(this.state.reportData21) + parseFloat(this.state.reportData22) + parseFloat(this.state.reportData23) + parseFloat(this.state.reportData24) + parseFloat(this.state.reportData25) + parseFloat(this.state.reportData26) + parseFloat(this.state.reportData212) + parseFloat(this.state.reportData213) + parseFloat(this.state.reportData214) + parseFloat(this.state.reportData218)) > 0 ? (parseFloat(this.state.reportData21) + parseFloat(this.state.reportData22) + parseFloat(this.state.reportData23) + parseFloat(this.state.reportData24) + parseFloat(this.state.reportData25) + parseFloat(this.state.reportData26) + parseFloat(this.state.reportData212) + parseFloat(this.state.reportData213) + parseFloat(this.state.reportData214) + parseFloat(this.state.reportData218)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</th>
                                                        <th>{(parseFloat(this.state.reportData31) + parseFloat(this.state.reportData32) + parseFloat(this.state.reportData33) + parseFloat(this.state.reportData34) + parseFloat(this.state.reportData35) + parseFloat(this.state.reportData36) + parseFloat(this.state.reportData312) + parseFloat(this.state.reportData313) + parseFloat(this.state.reportData314) + parseFloat(this.state.reportData318)) > 0 ? (parseFloat(this.state.reportData31) + parseFloat(this.state.reportData32) + parseFloat(this.state.reportData33) + parseFloat(this.state.reportData34) + parseFloat(this.state.reportData35) + parseFloat(this.state.reportData36) + parseFloat(this.state.reportData312) + parseFloat(this.state.reportData313) + parseFloat(this.state.reportData314) + parseFloat(this.state.reportData318)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</th>
                                                        <th>{(parseFloat(this.state.reportData41) + parseFloat(this.state.reportData42) + parseFloat(this.state.reportData43) + parseFloat(this.state.reportData44) + parseFloat(this.state.reportData45) + parseFloat(this.state.reportData46) + parseFloat(this.state.reportData412) + parseFloat(this.state.reportData413) + parseFloat(this.state.reportData414) + parseFloat(this.state.reportData418)) > 0 ? (parseFloat(this.state.reportData41) + parseFloat(this.state.reportData42) + parseFloat(this.state.reportData43) + parseFloat(this.state.reportData44) + parseFloat(this.state.reportData45) + parseFloat(this.state.reportData46) + parseFloat(this.state.reportData412) + parseFloat(this.state.reportData413) + parseFloat(this.state.reportData414) + parseFloat(this.state.reportData418)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</th>
                                                        <th>{(parseFloat(this.state.reportData51) + parseFloat(this.state.reportData52) + parseFloat(this.state.reportData53) + parseFloat(this.state.reportData54) + parseFloat(this.state.reportData55) + parseFloat(this.state.reportData56) + parseFloat(this.state.reportData512) + parseFloat(this.state.reportData513) + parseFloat(this.state.reportData514) + parseFloat(this.state.reportData518)) > 0 ? (parseFloat(this.state.reportData51) + parseFloat(this.state.reportData52) + parseFloat(this.state.reportData53) + parseFloat(this.state.reportData54) + parseFloat(this.state.reportData55) + parseFloat(this.state.reportData56) + parseFloat(this.state.reportData512) + parseFloat(this.state.reportData513) + parseFloat(this.state.reportData514) + parseFloat(this.state.reportData518)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</th>
                                                        <th>{(parseFloat(this.state.reportData61) + parseFloat(this.state.reportData62) + parseFloat(this.state.reportData63) + parseFloat(this.state.reportData64) + parseFloat(this.state.reportData65) + parseFloat(this.state.reportData66) + parseFloat(this.state.reportData612) + parseFloat(this.state.reportData613) + parseFloat(this.state.reportData614) + parseFloat(this.state.reportData618)) > 0 ? (parseFloat(this.state.reportData61) + parseFloat(this.state.reportData62) + parseFloat(this.state.reportData63) + parseFloat(this.state.reportData64) + parseFloat(this.state.reportData65) + parseFloat(this.state.reportData66) + parseFloat(this.state.reportData612) + parseFloat(this.state.reportData613) + parseFloat(this.state.reportData614) + parseFloat(this.state.reportData618)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</th>
                                                        <th>{(parseFloat(this.state.reportData71) + parseFloat(this.state.reportData72) + parseFloat(this.state.reportData73) + parseFloat(this.state.reportData74) + parseFloat(this.state.reportData75) + parseFloat(this.state.reportData76) + parseFloat(this.state.reportData712) + parseFloat(this.state.reportData713) + parseFloat(this.state.reportData714) + parseFloat(this.state.reportData718)) > 0 ? (parseFloat(this.state.reportData71) + parseFloat(this.state.reportData72) + parseFloat(this.state.reportData73) + parseFloat(this.state.reportData74) + parseFloat(this.state.reportData75) + parseFloat(this.state.reportData76) + parseFloat(this.state.reportData712) + parseFloat(this.state.reportData713) + parseFloat(this.state.reportData714) + parseFloat(this.state.reportData718)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</th>
                                                        <th></th>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'start' }}>Direct emissions from stationary combustion</td>

                                                        {/* co2 total */}
                                                        <td>
                                                            {parseFloat(this.state.reportTotalCategory2) > 0 ? parseFloat(this.state.reportTotalCategory2).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td>
                                                            {parseFloat(this.state.reportData12) > 0 ? parseFloat(this.state.reportData12).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td>
                                                            {parseFloat(this.state.reportData22) > 0 ? parseFloat(this.state.reportData22).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td>
                                                            {parseFloat(this.state.reportData32) > 0 ? parseFloat(this.state.reportData32).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>

                                                        {/* hfcs */}
                                                        <td>
                                                            {parseFloat(this.state.reportData42) > 0 ? parseFloat(this.state.reportData42).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td>
                                                            {parseFloat(this.state.reportData52) > 0 ? parseFloat(this.state.reportData52).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td>
                                                            {parseFloat(this.state.reportData62) > 0 ? parseFloat(this.state.reportData62).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        {/* nf3 */}
                                                        <td>
                                                            {parseFloat(this.state.reportData72) > 0 ? parseFloat(this.state.reportData72).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'start' }}>Direct emissions from mobile combustion</td>

                                                        <td>{parseFloat(this.state.reportTotalCategory1) > 0 ? parseFloat(this.state.reportTotalCategory1).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td>{parseFloat(this.state.reportData11) > 0 ? parseFloat(this.state.reportData11).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td>{parseFloat(this.state.reportData21) > 0 ? parseFloat(this.state.reportData21).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td>{parseFloat(this.state.reportData31) > 0 ? parseFloat(this.state.reportData31).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>

                                                        <td>{parseFloat(this.state.reportData41) > 0 ? parseFloat(this.state.reportData41).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td>{parseFloat(this.state.reportData51) > 0 ? parseFloat(this.state.reportData51).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td>{parseFloat(this.state.reportData61) > 0 ? parseFloat(this.state.reportData61).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td>{parseFloat(this.state.reportData71) > 0 ? parseFloat(this.state.reportData71).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'start' }}>Direct process emissions and removals arise from Industrial processes</td>

                                                        <td>{parseFloat(this.state.reportTotalCategory6) > 0 ? parseFloat(this.state.reportTotalCategory6).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td>{parseFloat(this.state.reportData16) > 0 ? parseFloat(this.state.reportData16).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td>{parseFloat(this.state.reportData26) > 0 ? parseFloat(this.state.reportData26).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td>{parseFloat(this.state.reportData36) > 0 ? parseFloat(this.state.reportData36).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>

                                                        <td>{parseFloat(this.state.reportData46) > 0 ? parseFloat(this.state.reportData46).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td>{parseFloat(this.state.reportData56) > 0 ? parseFloat(this.state.reportData56).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td>{parseFloat(this.state.reportData66) > 0 ? parseFloat(this.state.reportData66).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td>{parseFloat(this.state.reportData76) > 0 ? parseFloat(this.state.reportData76).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'start' }}>Direct fugitive emissions arise from the release of greenhouse gases in anthropogenic systems</td>

                                                        <td>{(parseFloat(this.state.reportTotalCategory3) + parseFloat(this.state.reportTotalCategory4) + parseFloat(this.state.reportTotalCategory14) + parseFloat(this.state.reportTotalCategory18)) > 0 ? (parseFloat(this.state.reportTotalCategory3) + parseFloat(this.state.reportTotalCategory4) + parseFloat(this.state.reportTotalCategory14) + parseFloat(this.state.reportTotalCategory18)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td>{(parseFloat(this.state.reportData13) + parseFloat(this.state.reportData14) + parseFloat(this.state.reportData114) + parseFloat(this.state.reportData118)) > 0 ? (parseFloat(this.state.reportData13) + parseFloat(this.state.reportData14) + parseFloat(this.state.reportData114) + parseFloat(this.state.reportData118)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td>{(parseFloat(this.state.reportData23) + parseFloat(this.state.reportData24) + parseFloat(this.state.reportData214) + parseFloat(this.state.reportData218)) > 0 ? (parseFloat(this.state.reportData23) + parseFloat(this.state.reportData24) + parseFloat(this.state.reportData214) + parseFloat(this.state.reportData218)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td>{(parseFloat(this.state.reportData33) + parseFloat(this.state.reportData34) + parseFloat(this.state.reportData314) + parseFloat(this.state.reportData318)) > 0 ? (parseFloat(this.state.reportData33) + parseFloat(this.state.reportData34) + parseFloat(this.state.reportData314) + parseFloat(this.state.reportData318)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td>{(parseFloat(this.state.reportData43) + parseFloat(this.state.reportData44) + parseFloat(this.state.reportData414) + parseFloat(this.state.reportData418)) > 0 ? (parseFloat(this.state.reportData43) + parseFloat(this.state.reportData44) + parseFloat(this.state.reportData414) + parseFloat(this.state.reportData418)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td>{(parseFloat(this.state.reportData53) + parseFloat(this.state.reportData54) + parseFloat(this.state.reportData514) + parseFloat(this.state.reportData518)) > 0 ? (parseFloat(this.state.reportData53) + parseFloat(this.state.reportData54) + parseFloat(this.state.reportData514) + parseFloat(this.state.reportData518)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td>{(parseFloat(this.state.reportData63) + parseFloat(this.state.reportData64) + parseFloat(this.state.reportData614) + parseFloat(this.state.reportData618)) > 0 ? (parseFloat(this.state.reportData63) + parseFloat(this.state.reportData64) + parseFloat(this.state.reportData614) + parseFloat(this.state.reportData618)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td>{(parseFloat(this.state.reportData73) + parseFloat(this.state.reportData74) + parseFloat(this.state.reportData714) + parseFloat(this.state.reportData718)) > 0 ? (parseFloat(this.state.reportData73) + parseFloat(this.state.reportData74) + parseFloat(this.state.reportData714) + parseFloat(this.state.reportData718)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'start' }}>Direct emissions and removals from Land Use, Land Use Change and Forestry</td>

                                                        <td>{(parseFloat(this.state.reportTotalCategory13) + parseFloat(this.state.reportTotalCategory19)) > 0 ? parseFloat(this.state.reportTotalCategory13 + this.state.reportTotalCategory19).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td>{(parseFloat(this.state.reportData113) + this.state.reportData119) > 0 ? parseFloat(this.state.reportData113 + this.state.reportData119).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td>{(parseFloat(this.state.reportData213) + this.state.reportData219) > 0 ? parseFloat(this.state.reportData213 + this.state.reportData219).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td>{(parseFloat(this.state.reportData313) + this.state.reportData319) > 0 ? parseFloat(this.state.reportData313 + this.state.reportData319).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td>{(parseFloat(this.state.reportData413) + this.state.reportData419) > 0 ? parseFloat(this.state.reportData413 + this.state.reportData419).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td>{(parseFloat(this.state.reportData513) + this.state.reportData519) > 0 ? parseFloat(this.state.reportData513 + this.state.reportData519).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td>{(parseFloat(this.state.reportData613) + this.state.reportData619) > 0 ? parseFloat(this.state.reportData613 + this.state.reportData619).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td>{(parseFloat(this.state.reportData713) + this.state.reportData719) > 0 ? parseFloat(this.state.reportData713 + this.state.reportData719).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ textAlign: 'start' }}>Direct emissions in tonnes of CO<sub>2</sub>e from biomass</th>

                                                        <th>{
                                                            parseFloat(this.state.reportTotalBiomass) +
                                                                parseFloat(this.state.reportTotalBiomass2) +
                                                                parseFloat(this.state.reportTotalBiomass3) +
                                                                parseFloat(this.state.reportTotalBiomass4) +
                                                                parseFloat(this.state.reportTotalBiomass5) +
                                                                parseFloat(this.state.reportTotalBiomass6) +
                                                                parseFloat(this.state.reportTotalBiomass7) > 0 ?
                                                                (
                                                                    parseFloat(this.state.reportTotalBiomass) +
                                                                    parseFloat(this.state.reportTotalBiomass2) +
                                                                    parseFloat(this.state.reportTotalBiomass3) +
                                                                    parseFloat(this.state.reportTotalBiomass4) +
                                                                    parseFloat(this.state.reportTotalBiomass5) +
                                                                    parseFloat(this.state.reportTotalBiomass6) +
                                                                    parseFloat(this.state.reportTotalBiomass7)
                                                                ).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</th>
                                                        <th>{parseFloat(this.state.reportTotalBiomass) > 0 ? parseFloat(this.state.reportTotalBiomass).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</th>
                                                        <th>{parseFloat(this.state.reportTotalBiomass2) > 0 ? parseFloat(this.state.reportTotalBiomass2).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</th>
                                                        <th>{parseFloat(this.state.reportTotalBiomass3) > 0 ? parseFloat(this.state.reportTotalBiomass3).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</th>

                                                        <th>{parseFloat(this.state.reportTotalBiomass4) > 0 ? parseFloat(this.state.reportTotalBiomass4).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</th>
                                                        <th>{parseFloat(this.state.reportTotalBiomass5) > 0 ? parseFloat(this.state.reportTotalBiomass5).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</th>
                                                        <th>{parseFloat(this.state.reportTotalBiomass6) > 0 ? parseFloat(this.state.reportTotalBiomass6).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</th>
                                                        <th>{parseFloat(this.state.reportTotalBiomass7) > 0 ? parseFloat(this.state.reportTotalBiomass7).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</th>
                                                        <th></th>
                                                    </tr>

                                                    <tr>
                                                        <td style={{ textAlign: 'start' }}>&nbsp;</td>
                                                        <td>&nbsp;</td>

                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>

                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                    </tr>

                                                    <tr>
                                                        <td style={{ textAlign: 'start' }}>Indirect Emissions in tonnes CO<sub>2</sub>e</td>

                                                        <td>{this.state.reportTotalCategory7 > 0 ? parseFloat(this.state.reportTotalCategory7).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>

                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>

                                                    <tr style={{ fontWeight: "bold" }}>
                                                        <th style={{ textAlign: 'start' }}>Category 2: Indirect GHG emissions from imported energy</th>

                                                        <th>0</th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>

                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'start' }}>Indirect emissions from imported electricity</td>

                                                        <td>{this.state.reportTotalCategory7 > 0 ? parseFloat(this.state.reportTotalCategory7).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>

                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'start' }}>Indirect emissions from imported energy</td>

                                                        <td>0</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>

                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>

                                                    <tr style={{ fontWeight: "bold" }}>
                                                        <th style={{ textAlign: 'start' }}>Category 3: Indirect GHG emissions from transportation</th>

                                                        <th>0</th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>

                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'start' }}>Emissions from Upstream transport and distribution for goods</td>

                                                        <td>{this.state.reportTotalCategory8 > 0 ? parseFloat(this.state.reportTotalCategory8).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>

                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'start' }}>Emissions from Downstreams transport and distribution for goods</td>

                                                        <td>{this.state.reportTotalCategory9 > 0 ? parseFloat(this.state.reportTotalCategory9).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>

                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'start' }}>Emissions from Employee commuting includes emissions</td>

                                                        <td>0</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>

                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'start' }}>Emissions from Client and visitor transport</td>

                                                        <td>0</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>

                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'start' }}>Emissions from Business travels</td>

                                                        <td>0</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>

                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>

                                                    <tr style={{ fontWeight: "bold" }}>
                                                        <th style={{ textAlign: 'start' }}>Category 4: Indirect GHG emissions from products used by organization</th>

                                                        <th>0</th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>

                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'start' }}>Emissions from Purchased goods</td>

                                                        <td>{this.state.reportTotalCategory10 > 0 ? parseFloat(this.state.reportTotalCategory10).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>

                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'start' }}>Emissions from Capital goods</td>

                                                        <td>{this.state.reportTotalCategory11 > 0 ? parseFloat(this.state.reportTotalCategory11).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>

                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'start' }}>Emissions from the disposal of solid and liquid waste</td>

                                                        <td>0</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>

                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'start' }}>Emissions from the use of assets</td>

                                                        <td>0</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>

                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'start' }}>Emissions from the use of services that are not described in the above subcategories (consulting, cleaning, maintenance, mail delivery, bank, etc.)</td>

                                                        <td>0</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>

                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>

                                                    <tr style={{ fontWeight: "bold" }}>
                                                        <th style={{ textAlign: 'start' }}>Category 5: Indirect GHG emissions associated with the use of products from the organization</th>

                                                        <th>{this.state.reportTotalCategory15 > 0 ? parseFloat(this.state.reportTotalCategory15).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>

                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'start' }}>Emissions or removals from the use stage of the product</td>

                                                        <td>0</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>

                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'start' }}>Emissions from downstream leased assets</td>

                                                        <td>0</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>

                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'start' }}>Emissions from end of life stage of the product</td>

                                                        <td>0</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>

                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'start' }}>Emissions from Investments</td>

                                                        <td>{this.state.reportTotalCategory17 > 0 ? parseFloat(this.state.reportTotalCategory17).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>

                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>

                                                    <tr style={{ fontWeight: "bold" }}>
                                                        <th style={{ textAlign: 'start' }}>Category 6: Indirect GHG emissions from other sources</th>

                                                        <th>0</th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>

                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>

                                                    <tr>
                                                        <td style={{ textAlign: 'start' }}>&nbsp;</td>
                                                        <td>&nbsp;</td>

                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>

                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                    </tr>

                                                    <tr>
                                                        <th style={{ textAlign: 'start' }}>TOTAL EMISSIONS CATEGORIES 1-6</th>

                                                        <th>{(
                                                            parseFloat(this.state.reportTotalCategory1) +
                                                            parseFloat(this.state.reportTotalCategory2) +
                                                            parseFloat(this.state.reportTotalCategory3) +
                                                            parseFloat(this.state.reportTotalCategory4) +
                                                            parseFloat(this.state.reportTotalCategory5) +
                                                            parseFloat(this.state.reportTotalCategory6) +
                                                            parseFloat(this.state.reportTotalCategory7) +
                                                            parseFloat(this.state.reportTotalCategory8) +
                                                            parseFloat(this.state.reportTotalCategory9) +
                                                            parseFloat(this.state.reportTotalCategory10) +
                                                            parseFloat(this.state.reportTotalCategory11) +
                                                            parseFloat(this.state.reportTotalCategory12) +
                                                            parseFloat(this.state.reportTotalCategory13) +
                                                            parseFloat(this.state.reportTotalCategory14) +
                                                            parseFloat(this.state.reportTotalCategory15) +
                                                            parseFloat(this.state.reportTotalCategory16) +
                                                            parseFloat(this.state.reportTotalCategory17) +
                                                            parseFloat(this.state.reportTotalCategory18) +
                                                            parseFloat(this.state.reportTotalCategory19)
                                                        ) > 0 ? (
                                                            parseFloat(this.state.reportTotalCategory1) +
                                                            parseFloat(this.state.reportTotalCategory2) +
                                                            parseFloat(this.state.reportTotalCategory3) +
                                                            parseFloat(this.state.reportTotalCategory4) +
                                                            parseFloat(this.state.reportTotalCategory5) +
                                                            parseFloat(this.state.reportTotalCategory6) +
                                                            parseFloat(this.state.reportTotalCategory7) +
                                                            parseFloat(this.state.reportTotalCategory8) +
                                                            parseFloat(this.state.reportTotalCategory9) +
                                                            parseFloat(this.state.reportTotalCategory10) +
                                                            parseFloat(this.state.reportTotalCategory11) +
                                                            parseFloat(this.state.reportTotalCategory12) +
                                                            parseFloat(this.state.reportTotalCategory13) +
                                                            parseFloat(this.state.reportTotalCategory14) +
                                                            parseFloat(this.state.reportTotalCategory15) +
                                                            parseFloat(this.state.reportTotalCategory16) +
                                                            parseFloat(this.state.reportTotalCategory17) +
                                                            parseFloat(this.state.reportTotalCategory18) +
                                                            parseFloat(this.state.reportTotalCategory19)
                                                        ).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>

                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>

                                                    <tr>
                                                        <td style={{ textAlign: 'start' }}>&nbsp;</td>
                                                        <td>&nbsp;</td>

                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>

                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                    </tr>

                                                    <tr style={{ fontWeight: "bold" }}>
                                                        <th style={{ textAlign: 'start' }}>REMOVALS</th>

                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>

                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                    <tr style={{ fontWeight: "bold" }}>
                                                        <td style={{ textAlign: 'start' }}>Direct removals in tonnes CO<sub>2</sub>e</td>

                                                        <td>{parseFloat(this.state.reportTotalRemoval) > 0 ? parseFloat(this.state.reportTotalRemoval).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td>{parseFloat(this.state.reportTotalRemoval) > 0 ? parseFloat(this.state.reportTotalRemoval).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td>{parseFloat(this.state.reportRemoval2) > 0 ? parseFloat(this.state.reportRemoval2).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td>{parseFloat(this.state.reportRemoval3) > 0 ? parseFloat(this.state.reportRemoval3).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>

                                                        <td>{parseFloat(this.state.reportRemoval4) > 0 ? parseFloat(this.state.reportRemoval4).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td>{parseFloat(this.state.reportRemoval5) > 0 ? parseFloat(this.state.reportRemoval5).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td>{parseFloat(this.state.reportRemoval6) > 0 ? parseFloat(this.state.reportRemoval6).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td>{parseFloat(this.state.reportRemoval7) > 0 ? parseFloat(this.state.reportRemoval7).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</td>
                                                        <td></td>
                                                    </tr>

                                                    <tr>
                                                        <td style={{ textAlign: 'start' }}>&nbsp;</td>
                                                        <td>&nbsp;</td>

                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>

                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                    </tr>

                                                    <tr style={{ fontWeight: "bold" }}>
                                                        <th style={{ textAlign: 'start' }}>STORAGE</th>

                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>

                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                    <tr style={{ fontWeight: "bold" }}>
                                                        <td style={{ textAlign: 'start' }}>Total storage as of year end in tonnes CO<sub>2</sub>e</td>

                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>

                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td>0</td>
                                                        <td></td>
                                                    </tr>

                                                    <tr>
                                                        <td style={{ textAlign: 'start' }}>&nbsp;</td>
                                                        <td>&nbsp;</td>

                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>

                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                    </tr>

                                                    <tr style={{ fontWeight: "bold" }}>
                                                        <th style={{ textAlign: 'start' }}>CARBON FINANCIAL INSTRUMENTS</th>

                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>

                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="col-lg-12 mt-4 overflow-auto p-0">
                                            <table className='table'>
                                                <tbody className='no-border'>
                                                    <tr style={{ fontWeight: "bold" }}>
                                                        <th style={{ textAlign: 'start' }}>Other related information</th>

                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>

                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={10} style={{ textAlign: 'start' }}>Performance tracking (emissions and removals by metric, e.g. tonnes CO<sub>2</sub>e per annual revenue)</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={10} style={{ textAlign: 'start' }}>Base year GHG emissions, removals, and stocks; and adjustments to base year</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={10} style={{ textAlign: 'start' }}>Disclosure of most significant sources, sinks, and reservoirs</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={10} style={{ textAlign: 'start' }}>Statements of emissions (CO<sub>2</sub>e) per unit of relevant units</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={10} style={{ textAlign: 'start' }}>Statement of emission reduction initiatives</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={10} style={{ textAlign: 'start' }}>Significancy criteria</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={10} style={{ textAlign: 'start' }}>Uncertainty assessment</td>
                                                    </tr>
                                                    <tr style={{ fontWeight: "bold" }}>
                                                        <th style={{ textAlign: 'start' }}>Notes</th>

                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>

                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={10} style={{ textAlign: 'start' }}>[*] Significant / Non Significant</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    count: state.counter.value,
    loggedIn: state.loggedIn.value,
    account: state.account.value,
    company: state.company.value,
});

const mapDispatchToProps = { increment, decrement, setLoggedIn, setAccount, setCompany };

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ReportISO));