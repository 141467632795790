import { useState } from 'react'
import ApexCharts from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import {
  EMPTY_CHART_COLORS,
  twoDecimalFormat,
  ROUTE_PATHS,
} from '../../library/helper'
import { useLocation } from 'react-router-dom'

const emptyXaxisCategories = [2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030]

export default function EmptyAreaChart(props) {
  const { i18n } = useTranslation()
  const location = useLocation()

  const [series] = useState(props.onDashboard
    ? [
      {
        "name": "actual emission - reduction",
        "data": [
          117.26,
          135.09,
          138.87,
          144.05,
          165.8,
          null,
          null,
          null,
          null,
          null,
          null,
          null
        ],
      },
      {
        "name": "actual emission",
        "data": [
          117.26,
          190.2,
          217.43,
          224.05,
          285.83,
          null,
          null,
          null,
          null,
          null,
          null,
          null
        ],
      },
      {
        "name": "emission forecast",
        "data": [
          null,
          null,
          null,
          null,
          285.83,
          362.39,
          459.46,
          582.53,
          738.56,
          936.38,
          1187.19,
          1505.18
        ],
      },
    ]
    : [
      {
        "name": "actual emission - reduction",
        "data": [
          117.26,
          135.09,
          138.87,
          144.05,
          165.8,
          null,
          null,
          null,
          null,
          null,
          null,
          null
        ],
      },
      {
        "name": "actual emission",
        "data": [
          117.26,
          190.2,
          217.43,
          224.05,
          285.83,
          null,
          null,
          null,
          null,
          null,
          null,
          null
        ],
      },
      {
        "name": "emission forecast",
        "data": [
          null,
          null,
          null,
          null,
          285.83,
          362.39,
          459.46,
          582.53,
          738.56,
          936.38,
          1187.19,
          1505.18
        ],
      },
      {
        "name": "Net Zero Indonesia",
        "data": [
          null,
          null,
          null,
          null,
          285.83,
          352.61,
          434.6,
          535.28,
          658.72,
          809.88,
          994.63,
          1220.4
        ],
      },
      {
        "name": "NDC - Unconditional",
        "data": [
          null,
          null,
          null,
          null,
          285.83,
          345.87,
          417.6,
          502.9,
          603.99,
          723.07,
          862.73,
          1025.18
        ],
      },
      {
        "name": "NDC - Conditional",
        "data": [
          null,
          null,
          null,
          null,
          285.83,
          340.03,
          402.76,
          474.7,
          556.21,
          647.41,
          747.57,
          854.94
        ],
      },
      {
        "name": "NDC & NZE",
        "data": [
          null,
          null,
          null,
          null,
          285.83,
          345.87,
          417.6,
          502.9,
          603.99,
          723.07,
          862.73,
          1025.18
        ],
      },
      {
        "name": "Custom Scenario",
        "data": [
          null,
          null,
          null,
          null,
          285.83,
          287.64,
          289.45,
          291.26,
          332.91,
          374.55,
          337.79,
          301.04,
        ],
      }
    ])

  const descretes = []
  for (let i = 0; i < series.length; i++) {
    for (let j = 0; j < series[i].data.length; j++) {
      if (
        i === 0 ||
        i === 1 ||
        (i === series.length - 1 &&
          (j === 7 || j === 9 || j === 11)
        )) {
        descretes.push({
          seriesIndex: i,
          dataPointIndex: j,
          fillColor: series[i].color,
          strokeColor: '#fff',
          size: 2,
          shape: "circle",
        })
      }
    }
  }

  const emptyOptions = {
    chart: {
      id: 'chartAreaEmpty',
      height: 280,
      toolbar: {
        show: false,
      },
      type: 'area',
      zoom: {
        enable: false,
      },
    },
    colors: EMPTY_CHART_COLORS,
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false,
    },
    stroke: {
      show: true,
      width: 2,
      curve: ['smooth', 'smooth', 'straight', 'straight', 'straight', 'straight', 'straight', 'straight']
    },
    xaxis: {
      type: 'year',
      categories: props.onDashboard ? emptyXaxisCategories.slice(0, 3) : emptyXaxisCategories,
      labels: {
        show: false,
      },
    },
    yaxis: {
      title: {
        text: location.pathname === ROUTE_PATHS.analyticsCarbonIntensity
          ? props.revenueUnit
            ? `tCO₂e/${props.revenueUnit}`
            : 'tCO₂e'
          : 'tCO₂e'
      },
      labels: {
        formatter: function (value) {
          return twoDecimalFormat(value, i18n.language)
        },
      },
      tickAmount: 4,
      min: 0,
    },
    tooltip: {
      enabled: false,
    },
    markers: {
      discrete: descretes
    },
  }

  return (
    <ApexCharts
      options={emptyOptions}
      series={series}
      type='area'
      height={props.onDashboard ? 200 : 465}
    />
  )
}
